import './css/file_right_menu.css';
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useGlobalClick } from './globalClickContext';
import { useWebpageEditor } from './webpageEditorContext';

const fileRightMenuContext = createContext(undefined);

export const FileRightMenuProvider = ({children}) => {
    //HOOKS
    const {addClickCallback, removeClickCallback} = useGlobalClick();
    const {setFileActive, closeFile, openCreateFolder, openCreateFile} = useWebpageEditor();

    //REFS
    const menuRef = useRef();
    const itemRef = useRef();

    //STATES
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [id, setId] = useState(0);
    const [type, setType] = useState(0); //0 FILE: 1:FOLDER 2:ROOT

    //FUNCTIONS
    const openFileContextMenu = (x, y, ref, id, typeC) => {
        setId(id);
        setType(typeC);
        if (itemRef.current) {
            itemRef.current.classList.remove('context');
        }

        itemRef.current = ref;
        setX(x);
        setY(y);

        menuRef.current.classList.add('active');
        itemRef.current.classList.add('context');
    }

    const openFile = () => {
        setFileActive(id);
        closeContextMenu();
    }

    const closeFileContext = () => {
        closeFile(id);
        closeContextMenu();
    }

    const openCreateFolderModal = () => {
        openCreateFolder(id);
        closeContextMenu();
    }

    const openCreateFileModal = () => {
        openCreateFile(id);
        closeContextMenu();
    }

    const closeContextMenu = () => {
        menuRef.current.classList.remove('active');
        if (itemRef.current) {
            itemRef.current.classList.remove('context');
        }
    }

    //EFFECTST
    useEffect(() => {
        addClickCallback(documentClick);

        return () => {
            removeClickCallback(documentClick);
        };
    }, []);

    //EVENT HANDLERS
    const documentClick = (e) => {
        if (!menuRef.current.contains(e.target)) {
            closeContextMenu();
        }
    }

    return (
        <fileRightMenuContext.Provider
            value={{
                openFileContextMenu,
            }}
        >
            <div 
                className='code_editor_file_context_menu'
                ref={menuRef}
                style={{
                    left: x + "px",
                    top: y + "px",
                }}
            >
                <div className='menu'>
                    {
                        type === 0 &&
                        <>
                            <button onClick={openFile}>
                                Open
                            </button>
                            <button onClick={closeFileContext}>
                                Close
                            </button>
                            <button>
                                Delete
                            </button>
                            <div className='divider'></div>
                            <button>
                                Rename
                            </button>
                        </>     
                    }

                    {
                        type === 1 &&
                        <>
                            <button onClick={openCreateFolderModal}>
                                New folder
                            </button>
                            <button onClick={openCreateFileModal}>
                                New file
                            </button>
                            <div className='divider'></div>
                            <button>
                                Delete
                            </button>
                            <button>
                                Rename
                            </button>
                        </>     
                    }

                    {
                        type === 2 &&
                        <>
                            <button onClick={openCreateFolderModal}>
                                New folder
                            </button>
                            <button onClick={openCreateFileModal}>
                                New file
                            </button>
                        </>     
                    }
                </div>
            </div>
            {children}
        </fileRightMenuContext.Provider>
    );
}

export const useFileRightMenu = () => useContext(fileRightMenuContext);