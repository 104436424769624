import { createContext, useContext, useEffect, useState } from "react";

const globalClickContext = createContext(undefined);

export const GlobalClickProvider = ({children}) => {

    //STATES
    const [clickCallbacks, setClickCallbacks] = useState([]);

    const addClickCallback = (callback) => {
        setClickCallbacks((prevCallbacks) => [...prevCallbacks, callback]);
    }

    const removeClickCallback = (callback) => {
        setClickCallbacks((prevCallbacks) =>
            prevCallbacks.filter((cb) => cb !== callback)
        );
    };

    const handleClick = (event) => {
        clickCallbacks.forEach((callback) => callback(event));
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
    
        return () => {
          document.removeEventListener("click", handleClick);
        };
    }, [clickCallbacks]);

    const value = {
        addClickCallback,
        removeClickCallback,
    };

    return (
        <globalClickContext.Provider value={value}>
            {children}
        </globalClickContext.Provider>
    );
}

export const useGlobalClick = () => useContext(globalClickContext);