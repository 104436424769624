import './ecommerce_product_variation_modal.css';
import { useEffect, useState } from 'react';
import { useEcommerceProductFrom } from '../../context/EcommerceProductFormContext';

export const EcommerceProductVariationModal = () => {
    const {
        addVariation,
        variationModal,
        currentVariation,
        setCurrentVariation,
        setVariationModal,
        variations,
        editVariation,
    } = useEcommerceProductFrom();

    //STATES
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [option, setOption] = useState("");
    const [options, setOptions] = useState([]);

    //FUNCTIONS
    const addOption = () => {
        if (option !== "") {
            setOptions((prev) => [...prev, {id: generateRandomToken(100), name: option}]);
            setOption("");
        }
    }

    const deleteItemById = (id) => {
        const updatedData = options.filter(item => item.id !== id);
        setOptions(updatedData);
    };

    const save = () => {
        if (id === "") {
            let variation = {
                id: generateRandomToken(100),
                name: name,
                options: options,
            }

            addVariation(variation);
            cancel();
        } else {
            let variation = {
                id,
                name: name,
                options: options,
            }
            editVariation(id, variation);
            cancel();
        }
    }

    const cancel = () => {
        setCurrentVariation(null);
        setVariationModal(false);
    }

    function generateRandomToken(length) {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let token = "";
      
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          token += charset.charAt(randomIndex);
        }
      
        return token;
    }

    useEffect(() => {
        if (currentVariation === null) {
            setId("");
            setName("");
            setOption("");
            setOptions([]);
        } else {
            let variation = variations.find(item => item.id === currentVariation);
            setId(variation.id);
            setName(variation.name);
            setOption("");
            setOptions(variation.options);
        }
    }, [currentVariation, variationModal]);

    return (
        <>
            <div className="product_variation_modal" style={variationModal ? {display : 'flex'} : {display : 'none'}}>
                <div className='form'>
                    <div className='header'>
                        <h2>
                            Variations
                        </h2>
                    </div>
                    <div className='body'>
                        <div className='input_group'>
                            <label>
                                Name
                            </label>
                            <input type='text' className='pretty_input' value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className='input_group'>
                            <label>
                                Option
                            </label>
                            <input type='text' className='pretty_input' value={option} onChange={(e) => setOption(e.target.value)} />
                            <button onClick={addOption} className='primary_btn' style={{width: 'fit-content', marginTop: "5px"}} disabled={option === ""}>Add</button>
                        </div>
                        <div className='options'>
                            {
                                options.map((option) => {
                                    return (
                                        <div className='option' key={option.id}>
                                            {option.name}
                                            <button onClick={() => deleteItemById(option.id)}>
                                                <i className="fas fa-times"></i>
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='footer'>
                        <div></div>
                        <div className='actions'>
                            <button className='secundary_btn' onClick={cancel}>
                                Cancel
                            </button>
                            <button className='primary_btn' onClick={save}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}