import { useImperativeHandle, useRef, useState } from "react";
import { forwardRef } from "react";

export const ControlledInputGroup = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            setMsgError: setMsgError,
            openError: openError,
            closeError: closeError,
            val: val,
            inputRef: inputRef,
        };
    });

    //REFS
    const inputRef = useRef();
    const errorRef = useRef();

    //STATES
    const [msgError, setMsgError] = useState("Something went wrong");

    //FUNCTIONS
    const openError = () => {
        errorRef.current.classList.add('active');
    }

    const closeError = () => {
        errorRef.current.classList.remove('active');
    }

    //IMITATION TO .val() JQUERY function
    const val = (value) => {
        if (value === null) {
            return props.value;
        }

        props.setValue(value);
    }
    
    //EVENT HANDLERS
    const onInputChange = (e) => {
        props.setValue(e.target.value);
    }

    return (
        <div className="input_group">
            <label>{props.label}</label>
            <input type={props.type} className="pretty_input" value={props.value} ref={inputRef} onChange={onInputChange} />
            <span className="error_msg" ref={errorRef}>
                {msgError}
            </span>
        </div>
    );
});