export const backendEnv = {
    url: 'https://backendatze.onlycarmanizales.com',
    afterAuthURL: '/dashboard',
};

export const endpoints = {
    register: '/atze/user/register',
    login: '/atze/user/login',
    websites_list_owned: '/websites/list/owned',
    website_get: '/websites/get',
    website_filesystem_get: '/website/filesystem/get',
    website_filesystem_file_save: '/website/filesystem/file/save',
    website_filesystem_file_bulk_save: '/website/filesytem/file/bulk/save',
    website_filesystem_folder_create: '/website/filesystem/folder/create',
    website_filesystem_file_create: '/website/filesystem/file/create',
    ecommerce_product_create: '/ecommerce/product/create',
    ecommerce_product_list: '/ecommerce/product/list',
    ecommerce_product_update: '/ecommerce/product/update',
    ecommerce_category_get : '/ecommerce/categories/get',
    ecommerce_category_create: '/ecommerce/categories/create',
    ecommerce_orders_get: '/ecommerce/orders/get',
    ecommerce_order_update: '/ecommerce/orders/update',
    chunked_upload_create: '/file/chucked_upload/create',
    chunked_upload_chunk: '/file/chucked_upload/chunk/upload',
    chunked_upload_merge: '/file/chucked_upload/chunk/merge',
    media_manager_folder_create: '/mediamanager/folder/create',
    media_manager_folder_get: '/mediamanager/folder/get',
    media_manager_folder_get_trace: '/mediamanager/folder/parent/trace',
    media_manager_file_create: '/mediamanager/file/create',
    media_manager_file_get: '/mediamanager/file/get',
}