import './ecommerce_order_item.css';
import DefaultProfilePic from '../../assets/img/nophoto.png';
import { useEcommerceOrders } from '../../context/EcommerceOrdersContext';

export const EcommerceOrderItem = ({order}) => {
    const { 
        getColombiaTime,
        EcommercePaymentMethodsName,
        EcommerceOrderStateName,
        EcommerceOrderStateClass,
        openOrder,
    } = useEcommerceOrders();

    return (
        <>
            <div className='ecommerce_order_item' onClick={() => openOrder(order)}>
                <div className='main_info'>
                    <div className='pic'>
                        <img src={DefaultProfilePic} alt='' />
                    </div>
                    <div className='info'>
                        <h2>{ order.contactDetails.name } { order.contactDetails.lastname }</h2>
                        <p>{ getColombiaTime(order.created_at) }</p>
                    </div>
                    <div className='payment_method'>
                        <p>
                            Pagó: { EcommercePaymentMethodsName[order.paymentMethod] }
                        </p>
                    </div>
                    <div className={'order_state ' + EcommerceOrderStateClass[order.state]}>
                        { EcommerceOrderStateName[order.state] }
                    </div>
                </div>
                <div className='actions'>
                    <button className='primary_btn' onClick={() => openOrder(order)}>
                        Ver pedido
                    </button>
                </div>
            </div>
        </>
    );
}