import './media_files_uploading.css';
import { useMediaManager } from '../../context/MediaManagerContext';
import { useState } from 'react';

export const MediaFilesUploading = () => {
    const { UploadingFiles } = useMediaManager();

    const [active, setActive] = useState(true);

    return (
        <>
            <div className={active ? 'media_files_uploading_container active' : 'media_files_uploading_container'} style={UploadingFiles.length === 0 ? {display:'none'}: {}}>
                <div className='header' onClick={() => setActive((prev) => !prev)}>
                    <span>
                        { UploadingFiles.length } Files being uploaded
                    </span>
                </div>
                <div className='uploading_body'>
                    {
                        UploadingFiles.map((file) => {
                            return file
                        })
                    }
                </div>
            </div>
        </>
    );
}