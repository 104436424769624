import './ecommerce_product_form.css';
import { EcommerceProductData } from '../EcommerceProductData/EcommerceProductData';
import { forwardRef } from "react";
import { useEcommerceProductFrom } from '../../context/EcommerceProductFormContext';
import { useMediaManager } from '../../context/MediaManagerContext';
import { backendEnv } from '../../env/ServerEnv';
import { EcommerceProductFormCategories } from '../EcommerceProductFormCategories/EcommerceProductFormCategories';

export const EcommerceProductForm = forwardRef((props, ref) => {
    //HOOKS
    const {
        status,
        setStatus,
        name,
        setName,
        description,
        setDescription,
        errorMsg,
        postAction,
        editingProduct,
        image,
        setImage,
        closeForm,
    } = useEcommerceProductFrom();

    const {
        openMediaManagerSelectFile,
        setMediaManagerOpen,
    } = useMediaManager();

    //FUNCTIONS
    const cancel = () => {
        closeForm();
    }

    const callbackImageSelect = (res) => {
        setMediaManagerOpen(false);
        setImage(res);
    }

    const openSelectImage = () => {
        openMediaManagerSelectFile(callbackImageSelect);
    }

    return (
        <>
            <div className='ecommerce_product_form'>
                <div className='header'>
                    <div className='info'>
                        {
                            editingProduct === null ? (
                                <h2>
                                    Add product
                                </h2>
                            ) : (
                                <h2>
                                    Edit product
                                </h2>
                            )
                        } 
                    </div>
                    <div className='actions'>
                        <button className='secundary_btn' onClick={cancel}>
                            Cancel
                        </button>
                        <button className='primary_btn' onClick={postAction}>
                            { editingProduct === null ? "Save" : "Update" }
                        </button>
                    </div>
                </div>

                {
                    errorMsg !== "" && (
                        <div className='ecommerce_product_form_error'>
                            {errorMsg}
                        </div>
                    )
                }

                <div className='body'>
                    <div className='col1'>
                        <div className='product_main_info form_inputs_container'>
                            <div className='input_container'>
                                <label>Product name</label>
                                <input type='text' placeholder='Product name' value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className='input_container'>
                                <label>Description</label>
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                            </div>
                        </div>

                        <EcommerceProductData />
                    </div>
                    <div className='col2'>
                        <div className='form_inputs_container'>
                            <div className='input_container'>
                                <label>Status</label>
                                <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value='0'>Active</option>
                                    <option value='1'>Inactive</option>
                                    <option value='2'>Hidden</option>
                                    <option value='3'>Out of stock</option>
                                </select>
                            </div>
                        </div>

                        <div className='form_inputs_container'>
                            <div className='input_container'>
                                <label>Image</label>
                                {
                                    image && (
                                        <div className='ecommerce_product_form_image'>
                                            <img src={backendEnv.url + image.path} alt='' />
                                        </div>
                                    )
                                }
                                
                            </div>
                            <button className='primary_btn' onClick={openSelectImage}>
                                Add image
                            </button>
                        </div>

                        <EcommerceProductFormCategories />
                    </div>
                </div>
            </div>
        </>
    );
});