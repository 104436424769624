import './file_tree_browser.css';
import { forwardRef } from "react";
import { FileTreeItem } from '../FileTreeItem/FileTreeItem';
import { useWebpageEditor } from '../../context/webpageEditorContext';

export const FileTreeBrowser = forwardRef((props, ref) => {
    const {fileRoot} = useWebpageEditor();

    return (
        <div className='filetree_filesystem_container'>
            {
                fileRoot && fileRoot.childs.map((child) => {
                    return (
                        <FileTreeItem element={child} depth={1} visible={true} key={child.childs ? 'file_tree_browser_item_folder_' + child.id : 'file_tree_browser_item_file_' + child.id} />
                    )
                })
            }
        </div>
    );
});