import './file_tree_item.css';
import { useFileRightMenu } from '../../context/FileRightMenuContext';
import { useWebpageEditor } from '../../context/webpageEditorContext';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

export const FileTreeItem = forwardRef(({element, depth, visible}, ref) => {
    useImperativeHandle(ref, () => {
        return {
            expanded: expanded,
            setExpanded: setExpanded,
        };
    });

    //HOOKS
    const {setFileActive} = useWebpageEditor();
    const {openFileContextMenu} = useFileRightMenu();

    //REFS
    const itemRef = useRef();
    const childsRef = useRef([]);

    //STATES
    const [expanded, setExpanded] = useState(false);
    
    //FUNCTIONS
    function toggleExpand() {
        setExpanded(!expanded);
    }

    function clickHandle() {
        if (element.childs) {
            toggleExpand();
        }
    }

    function doubleClickHandle() {
        if (!element.childs) {
            setFileActive(element.id);
        }
    }

    function handleContext(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!element.childs) {
            openFileContextMenu(e.clientX, e.clientY, itemRef.current, element.id, 0);
        } else if (element.childs) {
            openFileContextMenu(e.clientX, e.clientY, itemRef.current, element.id, 1);
        }
    }

    //EFFECTS
    useEffect(() => {
        expanded ? itemRef.current.classList.add('expanded') : itemRef.current.classList.remove('expanded');
    
        if (!expanded) {
            for (let i = 0; i < childsRef.current.length; i++) {
                const element = childsRef.current[i];
                if (element) {
                    if (element.setExpanded) {
                        element.setExpanded(false);
                    }
                }
            }
        }
    }, [expanded]);

    return (
        <>
            <div className='file_tree_item' 
                style={{
                    display: visible ? "flex" : "none"
                }} 
                onClick={clickHandle}
                onDoubleClick={doubleClickHandle}
                onContextMenu={handleContext}
                ref={itemRef}
            >
                <div className='depth_indicator' 
                    style={{
                        left: 8 * depth + "px",
                        display: depth > 1 ? "block" : "none",
                    }}
                >

                </div>
                <div className='item_row' style={{paddingLeft: 8 * depth + "px"}}>
                    <div className={element.childs ? 'content folder' : 'content file'}>
                        <div className='label'>
                            <span>
                                {element.name}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {
                element.childs && element.childs.map((child, index) => {
                    if (child.childs) {
                        return (
                            <FileTreeItem 
                                element={child} 
                                depth={depth + 1} 
                                visible={expanded} 
                                ref={(ref) => childsRef.current[index] = ref}
                                key={child.childs ? 'file_tree_browser_item_folder_' + child.id : 'file_tree_browser_item_file_' + child.id}
                            />
                        )
                    }

                    return <></>;
                })
            }
            {
                element.childs && element.childs.map(child => {
                    if (!child.childs) {
                        return (
                            <FileTreeItem element={child} depth={depth + 1} visible={expanded} key={child.childs ? 'file_tree_browser_item_folder_' + child.id : 'file_tree_browser_item_file_' + child.id} />
                        )
                    }

                    return <></>;
                })
            }
        </>  
    );
});