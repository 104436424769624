import { Route, Routes } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { Register } from "./page/register/Register";
import { useEffect } from "react";
import { Login } from "./page/login/Login";
import { ProtectedRoutes } from "./components/ProtectedRoutes/ProtectedRoutes";
import { Dashboard } from "./page/dashboard/Dashboard";
import { WebpageDashboardWrapper } from "./page/webpage_dashboard/WebpageDashboard";
import { CodeEditorWrapper } from "./page/code_editor/CodeEditor";

function App() {
	const {checkAuth, auth} = useAuth();

	useEffect(() => {
		if (auth === null) {
			checkAuth();
		} else if (!auth) {
			console.log("No auth");
		}
	}, [auth]);

  	return (
		<>
			<Routes>
				<Route path="/register" element={<Register />}></Route>
				<Route path="/login" element={<Login />}></Route>
			</Routes>

			<ProtectedRoutes>
				<Route path="/dashboard" element={<Dashboard />}></Route>
				<Route path="/dashboard/website/:id" element={<WebpageDashboardWrapper />}></Route>
				<Route path="/dashboard/website/:id/code_editor" element={<CodeEditorWrapper />}></Route>
			</ProtectedRoutes>
		</>
  	);
}

export default App;
