import './website_dashboard_template.css';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { forwardRef } from "react";
import { DashboardNavbar } from '../DashboardNavbar/DashboardNavbar';
import { WebpageDashboardNav } from '../WebpageDashboardNav/WebpageDashboardNav';
import { PageLoader } from '../PageLoader/PageLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useServer } from '../../context/serverContext';
import { endpoints } from '../../env/ServerEnv';


export const WebsiteDashboardTemplate = forwardRef((props, ref) => {
    //HOOKS
    useImperativeHandle(ref, () => {
        return {
            website: website,
        }
    });

    const { id } = useParams();
    const { getRequest } = useServer();
    const navigate = useNavigate();

    //STATES
    const [website, setWebsite] = useState(null);

    //REF
    const loaderRef = useRef();

    //FUNCTIONS
    const loadPage = () => {
        loaderRef.current.open();
        getRequest(endpoints.website_get, {id: id}, {}, (res) => {
            loaderRef.current.close();
            if (res[0]) {
                if (!res[1].success) {
                    navigate('/dashboard', {replace: true});
                } else {
                    setWebsite(res[1].website);
                }
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        loadPage();
    }, [id]);

    return (
        <>
            <div className='webpage_dashboard_app'>
                <DashboardNavbar />
                <div className='webpage_dashboard_container'>
                    <WebpageDashboardNav website={website} />
                    <div className='webpage_app_container'>
                        {
                            props.children
                        }
                    </div>
                </div>
            </div>
            <PageLoader ref={loaderRef} />
        </>
    );
});