import './media_folder_item.css';
import { DropdownIcon } from "../../icons/DropdownIcon";
import { FolderIcon } from "../../icons/FolderIcon";
import { useMediaManager } from '../../context/MediaManagerContext';

export const MediaFolderItem = ({folder}) => {
    const { setMediaParent, selectedElement, setSelectedElement } = useMediaManager();

    //FUNCTIONS
    const setSelected = () => {
        setSelectedElement({
            type: "folder",
            id: folder.id
        });
    }

    return (
        <div 
            className={(selectedElement && selectedElement.type === "folder" && selectedElement.id === folder.id) ? 'media_manager_folder_item selected' : 'media_manager_folder_item'}
            draggable 
            onClick={setSelected}
            onDoubleClick={() => setMediaParent(folder.id)}
        >
            <div className='icon'>
                <div className='icon_svg'>
                    <FolderIcon />
                </div>
            </div>
            <div className='name'>
                {folder.name}
            </div>
            <div className='more_options'>
                <DropdownIcon />
            </div>
        </div>
    );
}