import './login.css';
import bgVideo from '../../assets/video/blue_bg_gradient.mp4';
import { LogoVector1 } from '../../icons/logovector1';
import { SpinnerLoader } from '../../components/SpinnerLoader/SpinnerLoader';
import { ControlledInputGroup } from '../../components/ControlledInputGroup/ControlledInputGroup';
import { useEffect, useRef, useState } from 'react';
import { useServer } from '../../context/serverContext';
import { backendEnv, endpoints } from '../../env/ServerEnv';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export function Login() {
    //HOOKS
    const {postRequest} = useServer();
    const {auth, setToken} = useAuth();
    const navigate = useNavigate();

    //REFS
    const emailRef = useRef();
    const passwordRef = useRef();
    const loaderRef = useRef();

    //STATES
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [disabledSubmit, setDisabledSubmit] = useState(false);

    //FUNCTIONS
    const openLoader = () => {
        if (loaderRef.current) {
            loaderRef.current.classList.add('active');
        }
    }

    const closeLoader = () => {
        if (loaderRef.current) {
            loaderRef.current.classList.remove('active');
        }
    }

    const closeErrors = () => {
        emailRef.current.closeError();
        passwordRef.current.closeError();
    }
    
    const post = () => {
        setDisabledSubmit(true);
        openLoader();
        closeErrors();

        let data = {
            email: email,
            password: password,
        }

        postRequest(endpoints.login, data, {}, (res) => {
            setDisabledSubmit(false);
            closeLoader();

            if (res[0]) {
                res = res[1];

                if (!res.success) {
                    passwordRef.current.setMsgError("Wrong email or password");
                    passwordRef.current.openError();
                } else {
                    setToken(res.session_token);
                    redirectAuth();
                }
            }
        });
    }

    const redirectAuth = () => {
        navigate(backendEnv.afterAuthURL);
    }

    //EFFECTS
    useEffect(() => {
        if (auth === true) {
            redirectAuth();
        }
    }, [auth]);

    //EVENTHANDLERS

    return (
        <div className='login_page'>
            <div className='form_container'>
                <div className='media_container'>
                    <video className='background_video' autoPlay playsInline muted loop>
                        <source src={bgVideo} type='video/mp4' />
                    </video>
                </div>
                <div className='form_content'>
                    <LogoVector1 />
                    <div className='login_form'>
                        <h2>Login</h2>
                        <ControlledInputGroup 
                            label='Email'
                            type='text'
                            value={email}
                            setValue={setEmail}
                            ref={emailRef}
                        />
                        <ControlledInputGroup 
                            label='Password'
                            type='password'
                            value={password}
                            setValue={setPassword}
                            ref={passwordRef}
                        />
                        <div className='submit'>
                            <div className='loader' ref={loaderRef}>
                                <SpinnerLoader />
                            </div>
                            <button className='submit_btn' disabled={disabledSubmit} onClick={post}>
                                <span className='button-content'>
                                    Login
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}