import axios from "axios";
import { createContext, useContext } from "react";
import { backendEnv } from "../env/ServerEnv";
import { useAuth } from "./AuthContext";

const serverContext = createContext(undefined);

export const ServerProvider = ({children}) => {
    const api = axios.create({
        baseURL: backendEnv.url,
        withCredentials: true,
    });

    const {getToken} = useAuth();

    const postRequest = (url, data, options, callback) => {
        let requestOptions =  {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        };

        api.post(url, data, requestOptions).then(res => {
            callback([true, res.data]);
        }).catch((res) => {
            if (options.handleServerError === undefined || !options.handleServerError) {
                //HANDLE GLOBAL SERVER ERROR
            }

            callback([false, res]);
        });
    }

    const postRequestFormData = (url, formData, options, callback) => {
        let requestOptions =  {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                'Content-Type': 'multipart/form-data'
            }
        };

        api.post(url, formData, requestOptions).then(res => {
            callback([true, res.data]);
        }).catch((res) => {
            if (options.handleServerError === undefined || !options.handleServerError) {
                //HANDLE GLOBAL SERVER ERROR
            }

            callback([false, res]);
        });
    }

    const getRequest = (url, data, options, callback) => {
        let requestOptions =  {
            params: data,
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
        };

        api.get(url, requestOptions).then(res => {
            callback([true, res.data]);
        }).catch((res) => {
            if (options.handleServerError === undefined || !options.handleServerError) {
                //HANDLE GLOBAL SERVER ERROR
            }

            callback([false, res]);
        });
    }

    return (
        <serverContext.Provider 
            value={
                {
                    postRequest: postRequest,
                    getRequest: getRequest,
                    postRequestFormData,
                }
            }
        >
            {children}
        </serverContext.Provider>
    );
}

export const useServer = () => useContext(serverContext);