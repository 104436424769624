import './account_dropdown.css';
import nophoto from '../../assets/img/nophoto.png';
import { forwardRef, useImperativeHandle } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useRef } from 'react';

export const AccountDropdown = forwardRef((props, ref) => {
    //HOOKS
    const {user, logOut} = useAuth();

    //REFS
    const dropdownRef = useRef();

    //FUNCTIONS
    const toggleDropdown = () => {
        dropdownRef.current.classList.toggle('active');
    }

    return (
        <div className='account_dropdown'>
            <div className='label' onClick={toggleDropdown}>
                <div className='profile_picture'>
                    <img src={nophoto} alt="" />
                </div>
                <i className="fa-solid fa-chevron-down"></i>
            </div>
            <div className='dropdown_container' ref={dropdownRef}>
                <div className='dropdown'>
                    <div className='header'>
                        <div className='user_profile_picture'>
                            <img src={nophoto} alt="" />
                        </div>
                        <div className='user_name'>
                            {user && user.username}
                        </div>
                    </div>
                    <div className='body'>

                    </div>
                    <div className='footer'>
                        <div>

                        </div>
                        <button className='secundary_btn' onClick={logOut}>
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});