import axios from "axios";
import { createContext, useContext, useState } from "react";
import { backendEnv } from "../env/ServerEnv";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import UserModal from "../models/UserModel";

const AuthContext = createContext(undefined);

export const AuthProvider = ({children}) => {
    const api = axios.create({
        baseURL: backendEnv.url,
        withCredentials: true,
    });

    //STATES

    const [user, setUser] = useState(UserModal);
    const [auth, setAuth] = useState(null);

    //FUNCTIONS

    const checkAuth = () => {
        api.post('/auth/check', {}, {headers: {'Authorization': 'Bearer ' + getToken()}}).then(res => {
            res = res.data;
            setAuth(res.success);
            setUser(res.user);
        }).catch((res) => {
            //HANDLE GLOBAL SERVER ERROR
        });
    }

    const logOut = () => {
        api.post('/auth/check', {}, {headers: {'Authorization': 'Bearer ' + getToken()}}).then(res => {
            setToken("");
            setAuth(false);
        }).catch((res) => {
            //HANDLE GLOBAL SERVER ERROR
            setAuth(false);
        });
    }

    const getToken = () => {
        const cookies = new Cookies();
        return cookies.get("auth_token");
    }

    const setToken = (token) => {
        const cookies = new Cookies();
        let d = new Date();
        d.setTime(d.getTime() + (10 * 365 * 24 * 60 * 60 * 1000));

        cookies.set("auth_token", token, {
            expires: d,
        });

        checkAuth();
    }

    return (
        <AuthContext.Provider 
            value={{
                user: user,
                auth: auth,
                getToken: getToken,
                checkAuth: checkAuth,
                setToken: setToken,
                logOut: logOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);