import { useParams } from 'react-router-dom';
import { CloseIcon } from '../../icons/CloseIcon';
import { SpinnerLoader } from '../SpinnerLoader/SpinnerLoader';
import './code_editor_create_file_modal.css';
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useServer } from '../../context/serverContext';
import { endpoints } from '../../env/ServerEnv';

export const CodeEditorCreateFileModal = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            open: open,
            close: close,
            disabled: disabled,
            setDisabled: setDisabled,
            loaderRef: loaderRef,
        };
    });

    //HOOKS
    const {id} = useParams();
    const {postRequest} = useServer();

    //REFS
    const loaderRef = useRef();
    const modalRef = useRef();

    //STATES
    const [name, setName] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [parent, setParent] = useState(0);

    //FUNCTIONS
    const open = (parent = 0) => {
        setParent(parent);
        modalRef.current.classList.add('active');
    }

    const close = (forced = false) => {
        if (disabled && !forced) {
            return;
        }

        modalRef.current.classList.remove('active');
        setTimeout(() => {
            setName("");
        }, 100);
    }

    const postSave = () => {
        if (disabled) {
            return;
        }

        setDisabled(true);
        loaderRef.current.classList.add('active');
        setErrorMsg("");

        let data = {
            website_id: id,
            name: name,
            parent: parent,
        }

        postRequest(endpoints.website_filesystem_file_create, data, {}, (res) => {
            setDisabled(false);
            loaderRef.current.classList.remove('active');

            if (res[0]) {
                res = res[1];

                if (res.success) {
                    close();
                    props.getFileSystem();
                } else {
                    if (res.error_code === "validator_exception") {
                        setErrorMsg("The folder name is required");
                    } else if (res.error_code === "already_exist") {
                        setErrorMsg("A folder with that name already exist.");
                    } else {
                        setErrorMsg("Server error, please try again in a bit");
                    }
                }
            } else {
                setErrorMsg("Server error, please try again in a bit");
            }
        });
    }


    return (
        <div className="code_editor_create_file_modal" ref={modalRef}>
            <div className='modal'>
                <div className='toolbar'>
                    <button onClick={close}>
                        <CloseIcon />
                    </button>
                </div>
                <div className='message'>
                    <div className='message_container'>
                        <div className='title'>
                            <p>
                                Create a new file
                            </p>
                        </div>
                        <div className='input'>
                            <input type='text' className='primary_input' placeholder='File name' value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className='mesagge_content'>
                            {errorMsg}
                        </div>
                    </div> 
                </div>
                <div className='actions'>
                    <div className='loader' ref={loaderRef}>
                        <SpinnerLoader />
                    </div>
                    <div className='btns'>
                        <button className='code_editor_light_btn' onClick={postSave}>
                            Save
                        </button>
                        <button className='code_editor_secundary_btn' onClick={close}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});