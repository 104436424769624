import { createContext, useContext, useEffect, useState } from "react";
import { useWebsiteDashboard } from "./websiteDashboardContext";
import { useServer } from "./serverContext";
import { endpoints } from "../env/ServerEnv";
import { useParams } from "react-router-dom";

const EcommerceProductsContext = createContext(undefined);

export const EcommerceProductsProvider = ({children}) => {
    //HOOKS
    const {setLoadingContent} = useWebsiteDashboard();
    const {getRequest} = useServer();
    const {id} = useParams();

    //STATES
    const [products, setProducts] = useState([]);
    const [pageState, setPageState] = useState(0); // 0: PRODUCT LIST 1: PRODUCT CREATE 2: PRODUCT UPDATE

    //FUNCTIONS
    const loadProducts = () => {
        setLoadingContent(true);
        getRequest(endpoints.ecommerce_product_list, { atze_website_id: id}, {}, (res) => {
            setLoadingContent(false);
            if (res[0]) {
                res = res[1];
                setProducts(res.products);
            } else {    
                alert("Server error, try again in a bit");
            }
        });
    }

    const onProductCreateCancel = () => {
        setPageState(0);
    }

    //EFFECTS
    useEffect(() => {
        loadProducts();
    }, []);

    useEffect(() => {
        if (pageState === 0) {
            loadProducts();
        }
    }, [pageState]);

    const value = {
        products,
        setProducts,
        pageState,
        setPageState,
        loadProducts,
        id,
        onProductCreateCancel,
    }
    

    return (
        <EcommerceProductsContext.Provider
            value={value}
        >
            {children}
        </EcommerceProductsContext.Provider>
    );
}

export const useEcommerceProducts = () => useContext(EcommerceProductsContext);