import './ecommerce_product_data.css';
import { useEcommerceProductFrom } from '../../context/EcommerceProductFormContext';
import { forwardRef, useState } from "react";

export const EcommerceProductData = forwardRef((props, ref) => {
    //STATES
    const [option, setOption] = useState(0);

    return (
        <div className='product_data_form form_inputs_container'>
            <div className='header'>
                <h2>
                    Product data
                </h2>
            </div>
            <div className='product_data_body'>
                <div className='side_menu'>
                    <div className={option === 0 ? 'item active' : 'item'} onClick={() => setOption(0)}>
                        General
                    </div>
                    <div className={option === 1 ? 'item active' : 'item'} onClick={() => setOption(1)}>
                        Inventory
                    </div>
                    <div className={option === 4 ? 'item active' : 'item'} onClick={() => setOption(4)}>
                        Variations
                    </div>
                    {/* <div className={option === 2 ? 'item active' : 'item'} onClick={() => setOption(2)}>
                        Shipping
                    </div> */}
                    {/* <div className={option === 3 ? 'item active' : 'item'} onClick={() => setOption(3)}>
                        Advanced
                    </div> */}
                </div>
                <div className='content'>
                    {
                        option === 0 && <GeneralOption />
                    }
                    {
                        option === 1 && <InventoryOption />
                    }
                    {
                        option === 2 && <ShippingOption />
                    }
                    {
                        option === 4 && <VariationOption />
                    }
                </div>
            </div>
        </div>
    );
});

const VariationOption = () => {
    const { 
        variations,
        openAddVariation,
        openEditVariation,
        deleteVariation,
    } = useEcommerceProductFrom();

    return (
        <>
            {
                variations.map((variation) => {
                    return (
                        <div className='variation_option_item' key={variation.id}>
                            <div className='input_group'>
                                <label>Name</label>
                                <input type='text' value={variation.name} className='pretty_input' disabled />
                            </div>
                            <div className='options'>
                                {
                                    variation.options.map((option) => {
                                        return (
                                            <div className='option' key={option.id}>
                                                {option.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='actions'>
                                <button className='secundary_btn' onClick={() => deleteVariation(variation.id)}>
                                    Delete
                                </button>
                                <button className='primary_btn' onClick={() => openEditVariation(variation.id)}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    )
                })
            }
            
            
            <div className='variation_option_action'>
                <button className='primary_btn' onClick={openAddVariation}>
                    Add variation
                </button>
            </div>
        </>
    );
}

const GeneralOption = () => {
    //HOOKS
    const {
        regularPrice,
        setRegularPrice,
        salePrice,
        setSalePrice,
        saleActive,
        setSaleActive,
    } = useEcommerceProductFrom();

    return (
        <>
            <div className='input_container'>
                <label>Regular price $: </label>
                <input type='number' value={regularPrice} onChange={(e) => setRegularPrice(e.target.value)} />
            </div>
            <div className='input_container'>
                <label>Sale price $: </label>
                <input type='number' value={salePrice} onChange={(e) => setSalePrice(e.target.value)} />
            </div>
            <div className='input_container'>
                <label>Sale Active: </label>
                <select value={saleActive} onChange={(e) => setSaleActive(e.target.value)}>
                    <option value={0}>Inactive</option>
                    <option value={1}>Active</option>
                </select>
            </div>
        </>
    );
}

const InventoryOption = () => {
    const {
        sku,
        setSku,
        trackStock,
        setTrackStock,
        qty,
        setQty
    } = useEcommerceProductFrom();

    return (
        <>
            <div className='input_container'>
                <label>SKU: </label>
                <input type='text' value={sku} onChange={(e) => setSku(e.target.value)} />
            </div>
            <div className='input_container'>
                <label>Track stock quantity for this product: </label>
                <input type='checkbox' checked={trackStock} onClick={(e) => setTrackStock(prev => !prev)} />
            </div>
            {
                true && (
                    <div className='input_container'>
                        <label>Quantity: </label>
                        <input type='number' value={qty} onChange={(e) => setQty(e.target.value)} />
                    </div>
                )
            }
        </>
    );
}

const ShippingOption = () => {
    return (
        <>
            <div className='input_container'>
                <label>Weight (kg): </label>
                <input type='number' />
            </div>
            <div className='input_container'>
                <label>Dimensions (cm): </label>
                <input type='number' placeholder='Length' />
                <input type='number' placeholder='Width' />
                <input type='number' placeholder='Height' />
            </div>
        </>
    );
}