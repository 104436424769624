import './editor_window.css';
import { forwardRef, useEffect, useRef, useState } from "react";
import { Editor } from '@monaco-editor/react';
import { useWebpageEditor } from '../../context/webpageEditorContext';
import { MonacoNavItem } from '../MonacoNavItem/MonacoNavItem';
import { LogoSimpleNegative } from '../../icons/LogoSimpleNegative';

export const EditorWindow = forwardRef((props, ref) => {
    const {files, fileActive, openFiles, getContentRef, setEditorModels, editorModels} = useWebpageEditor();
    const file = files["file_" + fileActive];

    //REFS
    const editorRef = useRef(null);

    //STATES

    //FUNCIONS

    //EFFECTS

    //EVENT HANDLERS
    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
        editor.onDidChangeModel(() => {
            const model = editor.getModel();
            if (model) {
                model.updateOptions({ tabSize: 4 });

                setEditorModels(prevModels => ({
                    ...prevModels,
                    [model.uri.path]: model
                }));
            }
        });
    }

    return (
        <div className='editor_window'>
            <div className='logo'>
                <LogoSimpleNegative />
            </div>
            <div className='editor_monaco_nav'>
                {
                    openFiles.map(openFile => {
                        return (
                            <MonacoNavItem 
                                id={openFile}
                                key={'monaco_nav_file_' + openFile}
                            />
                        );
                    })
                }
            </div>
            <div className={file ? 'editor_monaco_container active' : 'editor_monaco_container'}>
                <Editor
                    height="100%"
                    width="100%"
                    theme='vs-dark'
                    onMount={handleEditorDidMount}
                    path={"file_" + fileActive}
                    defaultLanguage={file && file.languaje}
                    defaultValue={file && file.content}
                />
            </div>
        </div>
    );
});