import './media_folder_create.css';
import { useMediaManager } from '../../context/MediaManagerContext';
import { useServer } from '../../context/serverContext';
import { endpoints } from '../../env/ServerEnv';
import { BarLoader } from '../BarLoader/BarLoader';
import { useEffect, useState } from 'react';

export const MediaFolderCreate = () => {
    //HOOKS
    const { 
        mediaFolderCreateOpen, 
        setMediaFolderCreateOpen,
        MediaParent,
        websiteId,
        loadMediaFileSystem,
    } = useMediaManager();

    const { postRequest } = useServer();

    //STATES
    const [folderName, setFolderName] = useState("New folder");
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    //FUNCTIONS
    const postCreate = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setErrorMsg("");

        const data = {
            atze_website_id: websiteId,
            name: folderName,
            parent: MediaParent ? MediaParent : null,
        };

        postRequest(endpoints.media_manager_folder_create, data, {}, (res) => {
            setLoading(false);

            if (res[0]) {
                res = res[1];

                if (res.success) {
                    loadMediaFileSystem();
                    setMediaFolderCreateOpen(false);
                } else {
                    if (res.error_code === "validator_exception") {
                        setErrorMsg("All fields are required");
                    } else {
                        setErrorMsg("Server error, try again in a bit");
                    }
                }
            } else {
                setErrorMsg("Server error, try again in a bit");
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (!mediaFolderCreateOpen) {
            setFolderName("New folder");
            setErrorMsg("");
        }
    }, [mediaFolderCreateOpen]);

    return (
        <>
            <div className='media_manager_create_folder_modal'>
                <div className='form_container'>
                    <h2>
                        Create folder
                    </h2>
                    {
                        errorMsg !== "" && (
                            <div className='error_msg_container'>
                                {errorMsg}
                            </div>
                        )
                    }
                    <div className='input_group'>
                        <label>Folder name</label>
                        <input type='text' className='pretty_input' value={folderName} onChange={(e) => setFolderName(e.target.value)} />
                    </div>
                    {
                        loading && (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                    <div className='actions'>
                        <button className='secundary_btn' onClick={() => setMediaFolderCreateOpen(false)} disabled={loading}>
                            Cancel
                        </button>
                        <button className='primary_btn' disabled={loading} onClick={postCreate}>
                            Create
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}