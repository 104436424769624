import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ServerProvider } from './context/serverContext';
import { MediaManagerProvider } from './context/MediaManagerContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  	<BrowserRouter>
		<AuthProvider>
			<ServerProvider>
				<MediaManagerProvider>
					<App />
				</MediaManagerProvider>
			</ServerProvider>
		</AuthProvider>
  	</BrowserRouter>
);
