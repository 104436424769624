import './ecommerce_orders.css';
import { EcommerceOrdersProvider, useEcommerceOrders } from '../../context/EcommerceOrdersContext';
import { EcommerceOrderItem } from '../EcommerceOrderItem/EcommerceOrderItem';
import { EcommerceOrderView } from '../EcommerceOrderView/EcommerceOrderView';

export const EcommerceOrdersWrapper = () => {
    return (
        <EcommerceOrdersProvider>
            <EcommerceOrders />
        </EcommerceOrdersProvider>
    );
}

export const EcommerceOrders = () => {
    //HOOKS
    const { orders, orderOpen } = useEcommerceOrders();

    return (
        <>
            {
                !orderOpen && (
                    <div className='ecommerce_orders_app'>
                        <div className='header'>
                            <h2>
                                Orders
                            </h2>
                        </div>
                        <div className='body'>
                            <div className='header'>

                            </div>
                            {
                                orders.map((order) => {
                                    return <EcommerceOrderItem order={order}  />
                                })
                            }
                        </div>
                    </div>
                )
            }
            {
                orderOpen && <EcommerceOrderView />
            }
        </>
    );
}