import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export function ProtectRoute(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const {auth} = useAuth();

    useEffect(() => {
        if (location) {
            if (location.pathname === props.path && auth === false) {
                navigate('/login');
            }
        }
        
    }, [location, auth]);

    return <></>
}