import './code_editor_nav.css';
import { LogoSimple } from '../../icons/logosimple';
import { forwardRef, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useWebpageEditor } from '../../context/webpageEditorContext';
import { useGlobalClick } from '../../context/globalClickContext';

export const CodeEditorNav = forwardRef((props, ref) => {
    //HOOKS
    const {id} = useParams();
    const navigate = useNavigate();
    const {saveCurrentFile, saveAllOpenedFiles} = useWebpageEditor();
    const {addClickCallback, removeClickCallback} = useGlobalClick();

    //REFS
    const fileItemRef = useRef();

    //EFFECTS
    useEffect(() => {
        addClickCallback(documentClick);

        return () => {
            removeClickCallback(documentClick);
        };
    }, []);

    //EVENT HANDLER
    const documentClick = (e) => {
        if (!fileItemRef.current.contains(e.target)) {
            fileItemRef.current.classList.remove('open');
        }
    }

    return (
        <div className='code_editor_nav'>
            <div className='first_col'>
                <div className='logo'>
                    <LogoSimple />
                </div>
                <div className='dp_item' ref={fileItemRef}>
                    <button onClick={() => fileItemRef.current.classList.toggle('open')}>
                        File
                    </button>
                    <div className='dp_container'>
                        <div className='dp'>
                            <button>
                                New Text File
                            </button>
                            <button>
                                New File ...
                            </button>
                            <div className='divider'></div>
                            <button onClick={() => {saveCurrentFile(); fileItemRef.current.classList.remove('open'); }}>
                                Save
                            </button>
                            <button onClick={() => {saveAllOpenedFiles(); fileItemRef.current.classList.remove('open'); }}>
                                Save All
                            </button>
                            <button onClick={() => {navigate('/dashboard/website/' + id)}}>
                                Exit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});