import './code_editor_saving_modal.css';
import { DangerIcon } from '../../icons/DangerIcon';
import { SpinnerLoader } from '../SpinnerLoader/SpinnerLoader';
import { forwardRef, useImperativeHandle, useRef } from "react";

export const CodeEditorSavingModal = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            open: open,
            close: close,
        }
    });

    //REFS
    const modalRef = useRef();

    //FUNCTIONS
    const open = () => {
        modalRef.current.classList.add('active');
    }

    const close = () => {
        modalRef.current.classList.remove('active');
    }

    return (
        <div className='code_editor_saving_modal_file' ref={modalRef}>
            <div className='modal'>
                <div className='toolbar'>
                    <button>
                        
                    </button>
                </div>
                <div className='message'>
                    <div className='warning_icon'>
                        <DangerIcon />
                    </div>
                    <div className='message_container'>
                        <div className='title'>
                            <p>
                                Saving..., do not close this window
                            </p>
                        </div>
                        <div className='mesagge_content'>
                            Saving your changes on the server, do not close this browser window or you could loose your changes.
                        </div>
                    </div>
                </div>
                <div className='actions'>
                    <div className='loader active'>
                        <SpinnerLoader />
                    </div>
                    <div className='btns'>
                        
                    </div>
                </div>
            </div>
        </div>
    );
});