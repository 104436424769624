import './ecommerce_products.css';
import productFormImage from '../../assets/img/product_form.png';
import { EcommerceProductForm, EcommerceProductFormWrapper } from '../EcommerceProductForm/EcommerceProductForm';
import { EcommerceProductsList } from '../EcommerceProductsList/EcommerceProductsList';
import { EcommerceProductsProvider, useEcommerceProducts } from '../../context/EcommerceProductsContext';
import { EcommerceProductFormProvider } from '../../context/EcommerceProductFormContext';

export const EcommerceProductsWrapper = () => {
    return (
        <EcommerceProductsProvider>
            <EcommerceProductFormProvider>
                <EcommerceProducts />
            </EcommerceProductFormProvider>
        </EcommerceProductsProvider>
    );
}

export const EcommerceProducts = () => {
    //HOOKS
    const {
        pageState,
        setPageState,
        products,
        onProductCreateCancel
    } = useEcommerceProducts();

    return (
        <>
            {
                pageState === 0 && products.length === 0 && (
                    <div className='ecommerce_noproducts'>
                        <img src={productFormImage} alt='' />
                        <h2>
                            Start creating <span>your products</span>
                        </h2>
                        <button className='primary_btn' onClick={() => setPageState(1)}>
                            Add product
                        </button>
                    </div>
                )
            }
            {
                pageState === 0 && products.length > 0 && <EcommerceProductsList />
            }
            {
                pageState === 1 && <EcommerceProductForm cancelCallback={onProductCreateCancel} />
            }
        </>
    );
}