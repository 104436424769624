import './media_file_item.css';
import defaultFileImg from '../../assets/img/jpg/default_file_img.jpg';
import { DropdownIcon } from '../../icons/DropdownIcon';
import { useEffect, useState } from 'react';
import { backendEnv } from '../../env/ServerEnv';
import { Img } from 'react-image';
import { useMediaManager } from '../../context/MediaManagerContext';

export const MediaFileItem = ({file}) => {
    //HOOKS
    const { selectedElement, setSelectedElement } = useMediaManager();

    //CONSTANTS
    const imgTypes = ['jpg', 'jpeg', 'png', 'webp', 'JPG'];
    
    //STATE
    const [img, setImg] = useState(defaultFileImg);

    //FUNCTIONS
    const setSelected = () => {
        setSelectedElement({
            type: "file",
            id: file.id
        });
    }

    //EFFECTS
    useEffect(() => {
        if (imgTypes.includes(file.mime_type)) {
            setImg(backendEnv.url + file.path);
        }
    }, []);

    return (
        <div className={(selectedElement && selectedElement.type === "file" && selectedElement.id === file.id) ? 'media_manager_file_item selected' : 'media_manager_file_item'} draggable onClick={setSelected}>
            <div className='file_header'>
                <div className='icon'>
                    <div className='img'>
                        <img src="https://drive-thirdparty.googleusercontent.com/16/type/application/octet-stream" alt='' />
                    </div>
                </div>
                <div className='name'>
                    {file.name}
                </div>
                <div className='more_options'>
                    <DropdownIcon />
                </div>
            </div>
            <div className='file_body'>
                <div className='img'>
                    <img src={img} alt='' />
                </div>
            </div>
        </div>
    );
}