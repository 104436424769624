import './ecommerce_product_item.css';
import productDefaultImage from '../../assets/img/jpg/product_placeholder.jpg';
import { useEcommerceProductFrom } from '../../context/EcommerceProductFormContext';
import { useEcommerceProducts } from '../../context/EcommerceProductsContext';
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { backendEnv } from '../../env/ServerEnv';
import { DropdownIcon } from '../../icons/DropdownIcon';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageComponent = ({ imageUrl }) => {
    // Memoize the image component to prevent unnecessary re-renders
    const memoizedImage = useMemo(() => (
      <LazyLoadImage src={imageUrl} placeholderSrc='/product_placeholder.jpg' />
    ), [imageUrl]);
  
    return memoizedImage;
  };

export const EcommerceProductItem = forwardRef((props, ref) => {
    const { setEditingProduct } = useEcommerceProductFrom();
    const { setPageState } = useEcommerceProducts();

    const componentRef = useRef();

    const [img, setImg] = useState(productDefaultImage);

    const edit = () => {
        setEditingProduct(props.product);
        setPageState(1);
    }

    useEffect(() => {
        // if (props.product.image.path) {
        //     setImg(backendEnv.url + props.product.image.path);
        // }
    }, []);

    return (
        <>
            <div className='ecommerce_product_item' onClick={edit} ref={componentRef}>
                <div>

                </div>
                <div className='img_container'>
                    <div className='img'>
                        {/* <ImageComponent imageUrl={backendEnv.url + props.product.image.path} /> */}
                        {/* <img src={img} alt='' /> */}
                        {/* {
                            isVisible && <ImageComponent imageUrl={backendEnv.url + props.product.image.path} />
                        } */}
                    </div>
                </div>
                <div className='title'>
                    <h2>
                        { props.product.name }
                    </h2>
                </div>
                <div className='status'>
                    { props.product.status === 0 && <span>Active</span> }
                    { props.product.status === 1 && <span>Inactive</span> }
                    { props.product.status === 2 && <span>Hidden</span> }
                    { props.product.status === 3 && <span>Out of stock</span> }
                </div>
                <div className='inventory'>
                    {
                        props.product.trackStock ? (
                            <>
                                <span>
                                    { props.product.qty } in stock
                                </span>
                            </>
                        ) : (
                            <>
                                <span>
                                    Stock is not tracked
                                </span>
                            </>
                        )
                    }
                </div>
                {/* <div className='options_dropdown'>
                    <div className='toggle' onClick={optionsDropdown}>
                        <DropdownIcon />
                    </div>
                    <div className='dropdown'>

                    </div>
                </div> */}
            </div>
        </>
    );
});