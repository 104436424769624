import './code_editor_side_nav.css';
import { FilesIcon } from '../../icons/FilesIcon';
import { forwardRef } from "react";

export const CodeEditorSideNav = forwardRef((props, ref) => {
    return (
        <div className='code_editor_side_nav'>
            <div className='content'>
                <div className='nav_bar'>
                    <div className='item active'>
                        <FilesIcon />
                    </div>
                </div>
            </div>
        </div>
    );
});