import './web_page_dashboard_nav.css';
import { CodeIcon } from '../../icons/CodeIcon';
import { DashboardIcon } from '../../icons/DashboardIcon';
import { forwardRef } from "react";
import { PluginIcon } from '../../icons/PluginIcon';
import { SettingsIcon } from '../../icons/SettingsIcon';
import { Link } from 'react-router-dom';
import { ProductIcon } from '../../icons/ProductIcon';
import { useWebsiteDashboard } from '../../context/websiteDashboardContext';

export const WebpageDashboardNav = forwardRef((props, ref) => {
    const {
        dashboardEnum,
        currentDashboardOption,
        setCurrentDashboardOption,
    } = useWebsiteDashboard();

    return (
        <div className='wepagenav'>
            <div className='page_title'>
                <div className='page'>
                    <span>
                        {props.website && props.website.name} id: {props.website && props.website.id}
                    </span>
                </div>
            </div>
            <div className='items'>
                {/* <div className={ currentDashboardOption === dashboardEnum.DASHBOARD ? 'item active' : 'item'} onClick={() => setCurrentDashboardOption(dashboardEnum.DASHBOARD)}>
                    <DashboardIcon />
                    <span>
                        Dashboard
                    </span>
                </div> */}
                <div className={ currentDashboardOption === dashboardEnum.ECOMMERCE_ORDERS ? 'item active' : 'item'} onClick={() => setCurrentDashboardOption(dashboardEnum.ECOMMERCE_ORDERS)}>
                    <ProductIcon />
                    <span>
                        Orders
                    </span>
                </div>
                <div className={ currentDashboardOption === dashboardEnum.PRODUCTS ? 'item active' : 'item'} onClick={() => setCurrentDashboardOption(dashboardEnum.PRODUCTS)}>
                    <ProductIcon />
                    <span>
                        Products
                    </span>
                </div>
                {/* <div className='item'>
                    <SettingsIcon />
                    <span>
                        Settings
                    </span>
                </div> */}
                {/* <Link to={'/dashboard/website/'+(props.website? props.website.id : 0)+'/code_editor'} className='item'>
                    <CodeIcon />
                    <span>
                        Webpage Editor
                    </span>
                </Link> */}
                {/* <div className='item'>
                    <PluginIcon />
                    <span>
                        Plugins
                    </span>
                </div> */}
            </div>
        </div>
    );
});