import './ecommerce_order_view.css';
import { useEcommerceOrders } from '../../context/EcommerceOrdersContext';
import { EcommerceOrderProductItem } from '../EcommerceOrderProductItem/EcommerceOrderProductItem';
import { useState } from 'react';
import { useEffect } from 'react';
import { BarLoader } from '../BarLoader/BarLoader';
import { useServer } from '../../context/serverContext';
import { endpoints } from '../../env/ServerEnv';
import { useParams } from 'react-router-dom';

export const EcommerceOrderView = () => {
    //HOOKS
    const { 
        currentOrder, 
        getLocalePrice, 
        EcommercePaymentMethodsName,
        closeOrder,
        loadOrders,
    } = useEcommerceOrders();

    const { postRequest } = useServer();
    const { id } = useParams();
    //STATES
    const [orderState, setOrderState] = useState(0);
    const [carrier, setCarrier] = useState("");
    const [trackingNumber, setTrackingNumber] = useState("");
    const [loading, setLoading] = useState(false);

    //FUNCTIONS
    const updateOrder = () => {
        if (loading) {
            return;
        }

        setLoading(true);

        const data = {
            atze_website_id: id,
            order_id: currentOrder.id,
            state: orderState,
            carrier: carrier,
            trackingNumber: trackingNumber,
        }

        postRequest(endpoints.ecommerce_order_update, data, {}, (res) => {
            setLoading(false);
            loadOrders();
        });
    }

    //EFFECTS
    useEffect(() => {
        if (currentOrder !== null) {
            setOrderState(currentOrder.state);

            if (currentOrder.deliveryMethod === 1) {
                setCarrier(currentOrder.deliveryDetails.carrier);
                setTrackingNumber(currentOrder.deliveryDetails.trackingNumber);
            } else {
                setCarrier("");
                setTrackingNumber("");
            }
        }
    }, [currentOrder]);
    
    return (
        <>
            <div className='ecommerce_order_view'>
                <div className='header'>
                    <h2>Order #{currentOrder && currentOrder.id}</h2>
                    <div className='actions'>
                        <button className='secundary_btn' onClick={closeOrder} disabled={loading}>
                            Cerrar
                        </button>
                        <button className='primary_btn' onClick={updateOrder} disabled={loading}>
                            Guardar
                        </button>
                    </div>
                </div>
                {
                    loading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
                <div className='body'>
                    <div className='col1'>
                        <div className='products_container'>
                            <div className='header'>
                                <h2>
                                    Productos ({currentOrder && currentOrder.items.length})
                                </h2>
                            </div>
                            <div className='body'>
                                {
                                    currentOrder && currentOrder.items.map((item) => {
                                        return <EcommerceOrderProductItem product={item} />
                                    })
                                }
                            </div>
                            <div className='footer'>
                                <div>

                                </div>
                                <div className='total'>
                                    Total: {currentOrder && getLocalePrice(currentOrder.total)}
                                </div>
                            </div>
                        </div>

                        {
                            currentOrder && currentOrder.deliveryMethod === 1 && (
                                <div className='delivery_method'>
                                    <div className='header'>
                                        <h2>
                                            Detalles del envio
                                        </h2>
                                    </div>
                                    <div className='body'>
                                        <p className='item'>
                                            Nombre: {currentOrder.deliveryDetails.fullName}
                                        </p>
                                        <p className='item'>
                                            Telefono de contacto: {currentOrder.deliveryDetails.contactNumber}
                                        </p>
                                        <p className='item'>
                                            Dirección:&nbsp;
                                            { currentOrder.deliveryDetails.streetType }&nbsp;
                                            { currentOrder.deliveryDetails.street }&nbsp;
                                            { currentOrder.deliveryDetails.houseNumber1 }&nbsp;-&nbsp;
                                            { currentOrder.deliveryDetails.houseNumber2 }
                                        </p>
                                        <p className='item'>
                                            Barrio: { currentOrder.deliveryDetails.neighborhood }
                                        </p>
                                        <p className='item'>
                                            Municipio - Departamento: { currentOrder.deliveryDetails.city} - { currentOrder.deliveryDetails.state }
                                        </p>
                                        <p className='item'>
                                            Detalles del envio: { currentOrder.deliveryDetails.addressDetails }
                                        </p>
                                        <div className='input_group'>
                                            <label>
                                                Transportadora
                                            </label>
                                            <input type='text' className='pretty_input' value={carrier} onChange={(e) => setCarrier(e.target.value)} />
                                        </div>
                                        <div className='input_group'>
                                            <label>
                                                Numero de guia
                                            </label>
                                            <input type='text' className='pretty_input' value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            currentOrder && currentOrder.deliveryMethod === 0 && (
                                <div className='delivery_method'>
                                    <div className='header'>
                                        <h2>
                                            Detalles del envio
                                        </h2>
                                    </div>
                                    <div className='body'>
                                        <p className='pickup_method'>
                                            Compra para recoger en la tienda
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='col2'>
                        <div className='order_state'>
                            <div className='header'>
                                <h2>
                                    Estado de la compra 
                                </h2>
                            </div>
                            <div className='body'>
                                <div className='input_group'>
                                    <label>
                                        Estado
                                    </label>
                                    <select value={orderState} onChange={(e) => setOrderState(e.target.value)} className='pretty_input'>
                                        <option value={0}>Procesando</option>
                                        <option value={1}>Pendiente de pago</option>
                                        <option value={2}>Verificando pago</option>
                                        <option value={3}>Pago rechazado</option>
                                        <option value={4}>Pago exitoso</option>
                                        <option value={5}>Preparando pedido</option>
                                        <option value={6}>Enviando pedido</option>
                                        <option value={7}>Compra finalizada</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='ecommerce_order_contact_details'>
                            <div className='header'>
                                <h2>
                                    Datos de contacto
                                </h2>
                            </div>
                            <div className='body'>
                                <div className='item'>
                                    <p>Nombre: </p>
                                    <span>
                                        {currentOrder && currentOrder.contactDetails.name} {currentOrder && currentOrder.contactDetails.lastname}
                                    </span>
                                </div>
                                <div className='item'>
                                    <p>Correo: </p>
                                    <span>
                                        {currentOrder && currentOrder.contactDetails.email}
                                    </span>
                                </div>
                                <div className='item'>
                                    <p>Telefono: </p>
                                    <span>
                                        {currentOrder && currentOrder.contactDetails.phone}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='ecommerce_order_payment_method'>
                            <div className='header'>
                                <h2>
                                    Metodo de pago
                                </h2>
                                {/* <h2>
                                    $999.999
                                </h2> */}
                            </div>
                            <div className='body'>
                                <p>
                                    { currentOrder && EcommercePaymentMethodsName[currentOrder.paymentMethod] }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}