import './code_editor_delete_file_modal.css';
import { CloseIcon } from '../../icons/CloseIcon';
import { DangerIcon } from '../../icons/DangerIcon';
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { SpinnerLoader } from '../SpinnerLoader/SpinnerLoader';

export const CodeEditorDeleteFileModal = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            open: open,
            close: close,
            loaderRef: loaderRef,
            disable: disable,
            setDisable: setDisable,
        };
    });

    //REFS
    const modalRef = useRef();
    const loaderRef = useRef();

    //STATES
    const [file, setFile] = useState(null);
    const [disable, setDisable] = useState(false);

    //FUNCTIONS
    const open = (file) => {
        modalRef.current.classList.add('active');
        setFile({
            id: file.id,
            name: file.name
        });
    }

    const close = (force = false) => {
        if (disable && !force) {
            return;
        }

        setFile(null);
        modalRef.current.classList.remove('active');
        loaderRef.current.classList.remove('active');
    }

    const dontSave = () => {
        if (disable) {
            return;
        }

        props.dontSaveCallback(file.id);
    }

    const save = () => {
        if (disable) {
            return;
        }

        props.saveCallback(file.id);
    }

    return (
        <div className='code_editor_delete_modal_file' ref={modalRef}>
            <div className='modal'>
                <div className='toolbar'>
                    <button onClick={close}>
                        <CloseIcon />
                    </button>
                </div>
                <div className='message'>
                    <div className='warning_icon'>
                        <DangerIcon />
                    </div>
                    <div className='message_container'>
                        <div className='title'>
                            <p>
                                Do you want to save the changes you made to {file && file.name}?
                            </p>
                        </div>
                        <div className='mesagge_content'>
                            Your changes will be lost if you don't save them.
                        </div>
                    </div>
                </div>
                <div className='actions'>
                    <div className='loader' ref={loaderRef}>
                        <SpinnerLoader />
                    </div>
                    <div className='btns'>
                        <button className='code_editor_light_btn' onClick={save}>
                            Save
                        </button>
                        <button className='code_editor_secundary_btn' onClick={dontSave}>
                            Don't Save
                        </button>
                        <button className='code_editor_secundary_btn' onClick={close}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});