import './webpage_dashboard.css';
import { LogoSimpleNegative } from '../../icons/LogoSimpleNegative';
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { WebsiteDashboardTemplate } from '../../components/WebsiteDashboardTemplate/WebsiteDashboardTemplate';
import { WebsiteDashboardProvider, useWebsiteDashboard } from '../../context/websiteDashboardContext';
import { EcommerceProductsWrapper } from '../../components/EcommerceProducts/EcommerceProducts';
import { useMediaManager } from '../../context/MediaManagerContext';
import { EcommerceOrdersWrapper } from '../../components/EcommerceOrders/EcommerceOrders';

export function WebpageDashboardWrapper() {
    return (
        <WebsiteDashboardProvider>
            <WebpageDashboard />
        </WebsiteDashboardProvider>
    );
}

export function WebpageDashboard() {
    const {
        dashboardEnum,
        currentDashboardOption,
        loadingContent
    } = useWebsiteDashboard();

    const { setMediaManagerOpen } = useMediaManager();

    return (
        <>
            <WebsiteDashboardTemplate>
                <div className={loadingContent ? 'webpage_dashboard_content_loader active' : 'webpage_dashboard_content_loader'}>
                    <div className='logo'>
                        <LogoSimpleNegative />
                    </div>
                    <div className='loader'>
                        <BarLoader />
                    </div>
                </div>
                {
                    currentDashboardOption === dashboardEnum.DASHBOARD && (
                        <>
                            <button onClick={() => setMediaManagerOpen(true)}>
                                Open file Manager
                            </button>
                        </>
                    )
                }
                {
                    currentDashboardOption === dashboardEnum.PRODUCTS && <EcommerceProductsWrapper />
                }
                {
                    currentDashboardOption === dashboardEnum.ECOMMERCE_ORDERS && <EcommerceOrdersWrapper />
                }
            </WebsiteDashboardTemplate>
        </>
    )
}