import './register.css';
import bgVideo from '../../assets/video/blue_bg_gradient.mp4';
import { LogoVector1 } from '../../icons/logovector1';
import { SpinnerLoader } from '../../components/SpinnerLoader/SpinnerLoader';
import { ControlledInputGroup } from '../../components/ControlledInputGroup/ControlledInputGroup';
import { useRef, useState } from 'react';
import { useServer } from '../../context/serverContext';
import { backendEnv, endpoints } from '../../env/ServerEnv';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export function Register() {
    //HOOKS
    const {postRequest} = useServer();
    const {setToken} = useAuth();
    const navigate = useNavigate();

    //REFS
    const loaderRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();

    //STATES
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [disabledSubmit, setDisabledSubmit] = useState(false);

    //FUNCTIONS
    const openLoader = () => {
        loaderRef.current.classList.add('active');
    }

    const closeLoader = () => {
        loaderRef.current.classList.remove('active');
    }

    const validationException = (res) => {
        if (res.errors.username !== undefined) {
            nameRef.current.setMsgError("The name is required");
            nameRef.current.openError();
        }

        if (res.errors.email !== undefined) {
            emailRef.current.setMsgError("The email is required");
            emailRef.current.openError();
        }

        if (res.errors.password !== undefined) {
            passwordRef.current.setMsgError("The password is required");
            passwordRef.current.openError();
        }
    }

    const closeErrors = () => {
        nameRef.current.closeError();
        emailRef.current.closeError();
        passwordRef.current.closeError();
    }

    const redirectAuth = () => {
        navigate(backendEnv.afterAuthURL);
    }

    const post = () => {
        setDisabledSubmit(true);
        openLoader();
        closeErrors();
        
        let data = {
            username: name,
            email: email,
            password: password,
        }

        postRequest(endpoints.register, data, {}, (res) => {
            setDisabledSubmit(false);
            closeLoader();

            if (res[0]) {
                res = res[1];

                if (!res.success) {
                    switch (res.error_code) {
                        case "validator_exception":
                            validationException(res);
                            break;
                        case "email_exist":
                            emailRef.current.setMsgError("The email is already registered");
                            emailRef.current.openError();
                            break;
                        default:
                            break;
                    }
                } else {
                    setToken(res.session_token);
                    redirectAuth();
                }
            }
        });
    }

    //EFFECTS

    //EVENT HANDLERS

    return (
        <div className='register_page'>
            <div className='form_container '>
                <div className='media_container'>
                    <video className='background_video' autoPlay playsInline muted loop>
                        <source src={bgVideo} type='video/mp4' />
                    </video>
                </div>
                <div className='form_content'>
                    <LogoVector1 />
                    <div className='signup_form'>
                        <h2>Start today for free!</h2>
                        <ControlledInputGroup 
                            label='Name'
                            type='text'
                            value={name}
                            setValue={setName}
                            ref={nameRef}
                        />
                        <ControlledInputGroup 
                            label='Email'
                            type='text'
                            value={email}
                            setValue={setEmail}
                            ref={emailRef}
                        />
                        <ControlledInputGroup 
                            label='Password'
                            type='password'
                            value={password}
                            setValue={setPassword}
                            ref={passwordRef}
                        />
                        <div className='submit'>
                            <div className='loader' ref={loaderRef}>
                                <SpinnerLoader />
                            </div>
                            <button className='submit_btn' disabled={disabledSubmit} onClick={post}>
                                <span className='button-content'>
                                    Sign up now
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}