import './ecommerce_product_form_categories.css';
import { useEcommerceProductFrom } from '../../context/EcommerceProductFormContext';

export const EcommerceProductFormCategories = () => {
    const { 
        setAddingCategory, 
        categories, 
        setCategories,
        editingProduct,
        setDeletedCategory,
    } = useEcommerceProductFrom();

    const deleteCategory = (category) => {
        setCategories((prev) => prev.filter((item) => item.id !== category.id));

        if (editingProduct !== null) {
            setDeletedCategory((prev) => [...prev, category]);
        }
    }

    return (
        <div className='form_inputs_container'>
            <div className='input_container'>
                <label>
                    Categories
                </label>
                <div className='ecommerce_product_categories_container'>
                    {
                        categories.map((category) => {
                            return (
                                <div className='item' key={'product_ecommerce_category_' + category.id}>
                                    {category.name}
                                    <button onClick={() => deleteCategory(category)}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <button className='primary_btn' onClick={ () => setAddingCategory(true) }>
                Add category
            </button>
        </div>
    );
}