import { createContext, useContext, useEffect, useState } from "react";
import { useWebsiteDashboard } from "./websiteDashboardContext";
import { useParams } from "react-router-dom";
import { useServer } from "./serverContext";
import { endpoints } from "../env/ServerEnv";
import { useEcommerceProducts } from "./EcommerceProductsContext";
import { EcommerceCategoriesModal } from "../components/EcommerceCategoriesModal/EcommerceCategoriesModal";
import { EcommerceProductVariationModal } from "../components/EcommerceProductVariationModal/EcommerceProductVariationModal";

const EcommerceProductFormContext = createContext(undefined);

export const EcommerceProductFormProvider = ({children}) => {
    //HOOKS
    const {
        setLoadingContent,
    } = useWebsiteDashboard();

    const { setPageState } = useEcommerceProducts();


    const { id } = useParams();
    const { postRequest } = useServer();

    //STATES
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [regularPrice, setRegularPrice] = useState(0);
    const [salePrice, setSalePrice] = useState(0);
    const [saleActive, setSaleActive] = useState(0);
    const [sku, setSku] = useState("");
    const [trackStock, setTrackStock] = useState(false);
    const [qty, setQty] = useState(1);
    const [status, setStatus] = useState(0);
    const [image, setImage] = useState(null);
    const [categories, setCategories] = useState([]);

    const [errorMsg, setErrorMsg] = useState("");
    const [postDisabled, setPostDisabled] = useState(false);
    const [editingProduct, setEditingProduct] = useState(null);

    const [addingCategory, setAddingCategory] = useState(false);
    const [deletedCategory, setDeletedCategory] = useState([]);

    const [currentVariation, setCurrentVariation] = useState(null);
    const [variationModal, setVariationModal] = useState(false);
    const [variations, setVariations] = useState([]);

    //FUNCTIONS
    const closeForm = () => {
        clearInputs();

        if (editingProduct !== null) {
            setEditingProduct(null);
        }

        setPageState(0);
    }

    const postAction = () => {
        if (postDisabled) {
            return;
        }

        setPostDisabled(true);
        setLoadingContent(true);
        setErrorMsg("");

        let postCategories = [];

        for (let i = 0; i < categories.length; i++) {
            postCategories.push(categories[i].id);
        }

        let data = {
            atze_website_id: parseInt(id),
            main_image: image !== null ? image.id : null,
            regularPrice: regularPrice * 100,
            salePrice: salePrice * 100,
            SKU : sku,
            trackStock: trackStock,
            qty: qty,
            status: status,
            name: name,
            description: description,
            categories: postCategories,
            saleActive: parseInt(saleActive),
            variations: variations,
        }

        if (editingProduct === null) {
            createProduct(data);
        } else {
            updateProduct(data);
        }
    }
    
    const createProduct = (data) => {
        postRequest(endpoints.ecommerce_product_create, data, {}, (res) => {
            setLoadingContent(false);
            setPostDisabled(false);

            if (res[0]) {
                res = res[1];

                if (res.success) {
                    // setErrorMsg("Product succesfully created");
                    closeForm();
                    clearInputs();
                } else {
                    setErrorMsg("Please fill all required fields (Name)");
                }
            } else {
                setErrorMsg("Server error, try again in a bit");
            }
        });
    }

    const updateProduct = (data) => {
        data.id = editingProduct.id;
        
        let categoriesToDelete = [];

        for (let i = 0; i < deletedCategory.length; i++) {
            categoriesToDelete.push(deletedCategory[i].id);
        }

        data.deleted_categories = categoriesToDelete;

        postRequest(endpoints.ecommerce_product_update, data, {}, (res) => {
            setLoadingContent(false);
            setPostDisabled(false);

            if (res[0]) {
                res = res[1];
                closeForm();
                clearInputs();
            } else {
                setErrorMsg("Server error, try again in a bit");
            }
        });
    }

    const addVariation = (variation) => {
        setVariations((prev) => [...prev, variation]);
    }

    const openAddVariation = () => {
        setCurrentVariation(null);
        setVariationModal(true);
    }

    const openEditVariation = (id) => {
        setCurrentVariation(id);
        setVariationModal(true);
    }

    const editVariation = (id, variation) => {
        const updatedData = variations.map(item => (item.id === id ? variation : item));
        setVariations(updatedData);
    };

    const deleteVariation = (id) => {
        const updatedData = variations.filter(item => item.id !== id);
        setVariations(updatedData);
    };

    //EFFECTS
    useEffect(() => {
        if (editingProduct !== null) {
            setSku(editingProduct.SKU);
            setDescription(editingProduct.description);
            setName(editingProduct.name);
            setQty(editingProduct.qty);
            setRegularPrice(editingProduct.regularPrice / 100);
            setSalePrice(editingProduct.salePrice / 100);
            setStatus(editingProduct.status);
            setTrackStock(editingProduct.trackStock);
            setCategories(editingProduct.categories);
            setSaleActive(editingProduct.saleActive);
            setCurrentVariation(null);
            setVariations(editingProduct.variations);

            if (editingProduct.image) {
                setImage(editingProduct.image);
            } else {
                setImage(null);
            }
        }
    }, [editingProduct]);

    const clearInputs = () => {
        setRegularPrice(0);
        setSalePrice(0);
        setSku("");
        setTrackStock(false);
        setQty(1);
        setStatus(0);
        setName("");
        setDescription("");
        setImage(null);
        setCategories([]);
        setDeletedCategory([]);
        setSaleActive(0);
        setVariations([]);
        setCurrentVariation(null);
    }

    const values = {
        regularPrice,
        setRegularPrice,
        salePrice,
        setSalePrice,
        sku,
        setSku,
        trackStock,
        setTrackStock,
        qty,
        setQty,
        status,
        setStatus,
        name,
        setName,
        description,
        setDescription,
        errorMsg,
        setErrorMsg,
        createProduct,
        clearInputs,
        editingProduct,
        setEditingProduct,
        postAction,
        image,
        setImage,
        closeForm,
        addingCategory,
        setAddingCategory,
        categories,
        setCategories,
        deletedCategory,
        setDeletedCategory,
        saleActive,
        setSaleActive,
        variations,
        setVariations,
        addVariation,
        variationModal,
        setVariationModal,
        currentVariation,
        setCurrentVariation,
        openAddVariation,
        openEditVariation,
        editVariation,
        deleteVariation,
    }

    return (
        <EcommerceProductFormContext.Provider
            value={values}
        >
            <EcommerceCategoriesModal />
            <EcommerceProductVariationModal />
            {children}
        </EcommerceProductFormContext.Provider>
    );
}

export const useEcommerceProductFrom = () => useContext(EcommerceProductFormContext);