import './monaco_nav_item.css';
import { CloseIcon } from '../../icons/CloseIcon';
import { useWebpageEditor } from '../../context/webpageEditorContext';
import { forwardRef } from "react";

export const MonacoNavItem = forwardRef(({id}, ref) => {
    const {fileActive, setFileActive, files, closeFile} = useWebpageEditor();
    const file = files["file_" + id];

    //EVENT HANDLERS
    const handleClick = () => {
        setFileActive(id);
    }

    const handleClose = (e) => {
        e.stopPropagation();
        closeFile(id);
    }

    return (
        <div className={fileActive === id ? 'monaco_nav_item active' : 'monaco_nav_item'} onClick={handleClick}>
            <div className='active_indicator'></div>
            <div className='content'>
                <div className='title'>
                    <span>
                        <p>
                            {file && file.name}
                        </p>
                    </span>
                </div>
            </div>
            <div className='actions'>
                <button onClick={handleClose}>
                    <CloseIcon />
                </button>
            </div>
        </div>
    );
});