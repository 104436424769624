import { useState } from 'react';
import DefaultImage from '../../assets/img/jpg/product_placeholder.jpg';
import { useEcommerceOrders } from '../../context/EcommerceOrdersContext';
import { useEffect } from 'react';
import { backendEnv } from '../../env/ServerEnv';

export const EcommerceOrderProductItem = ({product}) => {
    const { getLocalePrice } = useEcommerceOrders();

    const [image, setImage] = useState(DefaultImage);

    useEffect(() => {
        if (product.image) {
            setImage(backendEnv.url + product.image);
        }
    }, [product]);

    return (
        <div className='ecommerce_order_product_item'>
            <div className='item'>
                <div className='info'>
                    <div className='pic'>
                        <img src={image} alt='' />
                    </div>
                    <h2 className='name'>
                        {product.name}
                    </h2>
                </div>
                <div className='price'>
                    <span>
                        {getLocalePrice(product.total)}
                    </span>
                </div>
            </div>
            <h2>
                Detalles
            </h2>
            <p className='detail'>
                <span>Cantidad:</span> {product.qty}
            </p>
            {
                product.variations.map((variation) => {
                    return (
                        <p className='detail'>
                            <span>{variation.name}:</span> {variation.option}
                        </p>
                    )
                })
            }
        </div>
    );
}