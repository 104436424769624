import './ecommerce_product_list.css';
import { forwardRef } from "react";
import {useEcommerceProducts} from '../../context/EcommerceProductsContext';
import { EcommerceProductItem } from '../EcommerceProductItem/EcommerceProductItem';

export const EcommerceProductsList = forwardRef((props, ref) => {
    //HOOKS
    const { products, setPageState } = useEcommerceProducts();

    return (
        <>
            <div className='ecommerce_product_list'>
                <div className='header'>
                    <div>
                        <h2>
                            Products
                        </h2>
                    </div>
                    <div className='actions' onClick={() => setPageState(1)}>
                        <button className='primary_btn'>
                            Add product
                        </button>
                    </div>
                </div>

                <div className='body'>
                    <div className='table_container'>
                        <div className='header'>
                            <div className='col1'>
                                <button className='active'>
                                    All
                                </button>
                                {/* <button>
                                    Active
                                </button>
                                <button>
                                    Inactive / Hidden
                                </button> */}
                            </div>
                            <div className='col2'>

                            </div>
                        </div>
                        <div className='data_header'>
                            <div></div>
                            <div></div>
                            <div>
                                Product
                            </div>
                            <div>
                                Status
                            </div>
                            <div>
                                Inventory
                            </div>
                        </div>
                        <div className='body'>
                            {
                                products && products.map((product) => {
                                    return <EcommerceProductItem product={product} key={'ecommerce_product_item_' + product.id} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});