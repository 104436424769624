import './my_sites_dropdown.css';
import { forwardRef, useImperativeHandle } from 'react';

export const MySitesDropdown = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {

        };
    });

    return (
        <div className='sites_dropdown'>
            <div className='label'>
                <p>My sites</p><i className="fa-solid fa-chevron-down"></i>
            </div>
        </div>
    );
});