import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaManager } from "./MediaManagerContext";

const websiteDashboardContext = createContext(undefined);

export const WebsiteDashboardProvider = ({children}) => {
    //HOOKS
    const { setWebsiteId } = useMediaManager();
    const { id } = useParams();

    const dashboardEnum = {
        DASHBOARD: 0,
        PRODUCTS: 1,
        WEBPAGE_EDITOR: 2,
        ECOMMERCE_ORDERS: 3,
    };

    //STATES
    const [currentDashboardOption, setCurrentDashboardOption] = useState(dashboardEnum.ECOMMERCE_ORDERS);
    const [loadingContent, setLoadingContent] = useState(false);

    useEffect(() => {
        setWebsiteId(id);
    }, [id]);

    const value = {
        dashboardEnum,
        currentDashboardOption,
        setCurrentDashboardOption,
        loadingContent,
        setLoadingContent,
        websiteId: id,
    };

    return (
        <websiteDashboardContext.Provider
            value={value}
        >
            { children }
        </websiteDashboardContext.Provider>
    );
}

export const useWebsiteDashboard = () => useContext(websiteDashboardContext);