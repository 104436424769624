import { useEffect, useState } from "react";
import { useMediaManager } from "../../context/MediaManagerContext";
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env/ServerEnv";

export const MediaFileUpload = ({file, parent}) => {
    //HOOKS
    const { websiteId, MediaParent, loadMediaFileSystem } = useMediaManager();
    const { postRequest, postRequestFormData } = useServer();

    //CONSTANTS
    const chuckSize = (1024 * 1024) * 30;
    let initialParent = null;

    //STATES
    const [progress, setProgress] = useState(0);

    //FUNCTIONS
    const getChunks = () => {
        const chunks = [];
        let offset = 0;

        while (offset < file.size) {
            const end = Math.min(offset + chuckSize, file.size);
            const chunck = {
                offset: offset, 
                end: end,
                length: end - offset,
            };
            chunks.push(chunck);
            offset = end;
        }

        return chunks;
    }

    const createMediaFile = (uploadId) => {
        const data = {
            "atze_website_id" : websiteId,
            "parent" : initialParent,
            "chunked_upload_id" : uploadId,
        }

        postRequest(endpoints.media_manager_file_create, data, {}, (res) => {
            setProgress(102);
            if (initialParent === MediaParent) {
                loadMediaFileSystem();
            }
        });
    }

    const mergeUploadedChunks = (uploadId) => {
        setProgress(101);
        const data = {
            upload_id: uploadId
        };

        postRequest(endpoints.chunked_upload_merge, data, {}, (res) => {
            createMediaFile(uploadId);
        });
    }

    const uploadChunk = (uploadId, chunks, index) => {
        //PERCENTAJE
        const percentaje = Math.round(((index + 1) / chunks.length) * 100);
        setProgress(percentaje);

        let formData = new FormData();
        formData.append("upload_id", uploadId);
        formData.append("index", index);
        formData.append("chunk", file.slice(chunks[index].offset, chunks[index].end));

        postRequestFormData(endpoints.chunked_upload_chunk, formData, {}, (res) => {
            if (res[0] && res[1].success) {
                if (index >= chunks.length - 1) {
                    mergeUploadedChunks(uploadId);
                } else {
                    uploadChunk(uploadId, chunks, ++index);
                }
            } else {
                uploadChunk(uploadId, chunks, index);
            }
        });
    }

    const initChunkedUpload = (chunks) => {
        let data = {
            atze_website_id: parseInt(websiteId),
            name: file.name,
            size: file.size,
            chunk_count: chunks.length,
        }

        postRequest(endpoints.chunked_upload_create, data, {}, (res) => {
            if (res[0]) {
                res = res[1];
                uploadChunk(res.chunkedUpload.id, chunks, 0);
            } else {

            }
        });
    }

    const uploadFile = () => {
        const chunks = getChunks();
        initChunkedUpload(chunks);
    }

    useEffect(() => {
        initialParent = parent;
        if (progress < 100) {
            uploadFile();
        }
    }, []);

    return (
        <>
            <div className='file' key={'uploading_file_' + file.id}>
                <h2>
                    { file.name }
                </h2>
                    {
                        progress <= 100 && <span>{progress}%</span>
                    }
                    {
                        progress === 101 && <span>PROCESING</span>
                    }
                    {
                        progress === 102 && <span>COMPLETED</span>
                    }
            </div>
        </>
    );
}