import './ecommerce_categories_modal.css';
import { useServer } from '../../context/serverContext';
import { useWebsiteDashboard } from '../../context/websiteDashboardContext';
import { endpoints } from '../../env/ServerEnv';
import { BarLoader } from '../BarLoader/BarLoader';
import { useEffect, useState } from 'react';
import { useEcommerceProductFrom } from '../../context/EcommerceProductFormContext';

export const EcommerceCategoriesModal = () => {
    //HOOKS
    const { websiteId } = useWebsiteDashboard();
    const { getRequest, postRequest } = useServer();
    const { 
        addingCategory, 
        setAddingCategory,
        categories: productCategories,
        setCategories: setProductCategories,
        deletedCategory,
        setDeletedCategory,
    } = useEcommerceProductFrom();

    //STATES
    const [name, setName] = useState("");
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [loading, setLoading] = useState(false);

    //FUNCTIONS
    const getCategories = () => {
        setLoading(true);
        const data = {
            atze_website_id: websiteId,
            query: name,
        }

        getRequest(endpoints.ecommerce_category_get, data, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];
                setCategories(res.categories);
            }
        });
    }

    const createCategory = () => {
        setLoading(true);

        const data = {
            atze_website_id: websiteId,
            name: name,
        }

        postRequest(endpoints.ecommerce_category_create, data, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                getCategories();
            }
        });
    }

    const select = () => {
        const category = categories.find(item => item.id === selectedCategory);
        const existing = productCategories.find(item => item.id === selectedCategory) || null;

        if (existing === null) {
            setProductCategories((prev) => [...prev, category]);

            const pendingToDelete = deletedCategory.find(item => item.id === selectedCategory) || null;

            if (pendingToDelete !== null) {
                setDeletedCategory((prev) => prev.filter((item) => item.id !== selectedCategory));
            }
        }

        setName("");
        setSelectedCategory(-1);

        setAddingCategory(false);
    }

    //EFFECTS
    useEffect(() => {
        getCategories();
        setSelectedCategory(-1);
    }, [name]);

    return (
        <>
            <div className='ecommerce_categories_modal_container' style={addingCategory ? { display: 'flex'} : { display : 'none'} }>
                <div className='ecommerce_categories_modal'>
                    <div className='header'>
                        <h2>
                            Categories
                        </h2>
                    </div>
                    <div className='body'>
                        <div className='input_group'>
                            <label>
                                Name
                            </label>
                            <input type='text' placeholder='Search...' className='pretty_input' value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        {
                            loading && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                        <div className='categories_container'>
                            {
                                categories.map((category) => {
                                    return (
                                        <div className={selectedCategory === category.id ? 'item active' : 'item'} key={'ecommerce_category_' + category.id} onClick={() => setSelectedCategory(category.id)}>
                                            <span>
                                                {category.name}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            !loading && name !== "" && (
                                <button className='create_btn primary_btn' onClick={createCategory}>
                                    Create {name}
                                </button>
                            )
                        }
                    </div>
                    <div className='footer'>
                        <div>

                        </div>
                        <div className='actions'>
                            <button className='secundary_btn' onClick={() => setAddingCategory(false) }>
                                Cancel
                            </button>
                            <button className='primary_btn' disabled={selectedCategory === -1 || loading} onClick={select}>
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}