import './code_editor.css';
import { FileTreeBrowser } from '../../components/FileTreeBrowser/FileTreeBrowser';
import { EditorWindow } from '../../components/CodeEditor/EditorWindow';
import {WebpageEditorProvider } from '../../context/webpageEditorContext';
import { CodeEditorSideNav } from '../../components/CodeEditorSideNav/CodeEditorSideNav';
import { CodeEditorNav } from '../../components/CodeEditorNav/CodeEditorNav';
import { GlobalClickProvider } from '../../context/globalClickContext';
import { FileRightMenuProvider, useFileRightMenu } from '../../context/FileRightMenuContext';
import { useRef } from 'react';

export function CodeEditorWrapper () {
    return (
        <GlobalClickProvider>
            <WebpageEditorProvider>
                <FileRightMenuProvider>
                    <CodeEditor />
                </FileRightMenuProvider>
            </WebpageEditorProvider>
        </GlobalClickProvider>
    );
}

export function CodeEditor() {
    const {openFileContextMenu} = useFileRightMenu();

    const fileBrowserRef = useRef();

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        openFileContextMenu(e.clientX, e.clientY, fileBrowserRef.current, 0, 2);
    }

    return (
        <div className='code_editor_app'>
            <CodeEditorNav />
            <CodeEditorSideNav />
            <div className='filetree_browser_container' onContextMenu={handleContextMenu} ref={fileBrowserRef}>
                <div className='header'>
                    <div className='title'>
                        <h2>
                            EXPLORER
                        </h2>
                    </div>
                </div>
                <FileTreeBrowser />
            </div>

            <EditorWindow />
        </div>
    );
}