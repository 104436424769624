import { useContext, useEffect, useState } from "react";
import { MediaManager } from "../components/MediaManager/MediaManager";
import { useServer } from "./serverContext";
import { endpoints } from "../env/ServerEnv";
import { MediaFolderCreate } from "../components/MediaFolderCreate/MediaFolderCreate";
import { MediaFileUpload } from "../components/MediaFileUpload/MediaFileUpload";

const { createContext } = require("react");

const MediaManagerContext = createContext(undefined);

export const MediaManagerProvider = ({children}) => {
    //HOOKS
    const { getRequest } = useServer();

    //STATE
    const [mediaManagerOpen, setMediaManagerOpen] = useState(false);
    const [mediaFolderCreateOpen, setMediaFolderCreateOpen] = useState(false);
    const [websiteId, setWebsiteId] = useState(null);

    const [MediaParent, setMediaParent] = useState(null);
    const [MediaFolders, setMediaFolders] = useState([]);
    const [MediaFoldersLoading, setMediaFoldersLoading] = useState(false);
    const [MediaFiles, setMediaFiles] = useState([]);
    const [MediaFilesLoading, setMediaFilesLoading] = useState(false);
    const [MediaFoldersTrace, setMediaFoldersTrace] = useState([]);

    const [UploadingFiles, setUploadingFiles] = useState([]);

    const [selectedElement, setSelectedElement] = useState(null);
    const [selectedCallback, setSelectedCallback] = useState(null);
    
    //FUNCTIONS
    const uploadFiles = (files) => {
        let filesToUpload = [];
        for (let i = 0; i < files.length; i++) {
            filesToUpload.push(<MediaFileUpload file={files[i]} parent={MediaParent} />);
        }

        setUploadingFiles((prev) => [...prev, ...filesToUpload]);
    }

    const cancelMediaManager = () => {
        setMediaManagerOpen(false);
    }

    const loadMediaFileSystem = () => {
        const data = {
            atze_website_id: websiteId,
            parent: MediaParent ? MediaParent : null,
        }

        setMediaFoldersLoading(true);
        getRequest(endpoints.media_manager_folder_get, data, {}, (res) => {
            setMediaFoldersLoading(false);
            if (res[0]) {
                res = res[1];
                setMediaFolders(res.folders);
            }
        });

        setMediaFilesLoading(true);
        getRequest(endpoints.media_manager_file_get, data, {}, (res) => {
            setMediaFilesLoading(false);
            if (res[0]) {
                res = res[1];
                setMediaFiles(res.mediaFiles);
            }
        });

        if (MediaParent !== null) {
            getRequest(endpoints.media_manager_folder_get_trace, { atze_website_id: websiteId, media_folder_id: MediaParent}, {}, (res) => {
                if (res[0]) {
                    res = res[1];
                    setMediaFoldersTrace(res.parents);
                }
            });
        }
    }

    const openMediaManagerSelectFile = (callback) => {
        setMediaManagerOpen(true);
        setSelectedCallback(() => callback);
    }

    //EFFECTS
    useEffect(() => {
        if (mediaManagerOpen) {
            loadMediaFileSystem();
        } else {
            setMediaParent(null);
            setMediaFolders([]);
            setMediaFiles([]);
            setMediaFoldersTrace([]);
            setSelectedElement(null);
        }
    }, [mediaManagerOpen]);

    useEffect(() => {
        if (mediaManagerOpen) {
            setMediaFolders([]);
            setMediaFiles([]);
            setMediaFoldersTrace([]);
            setSelectedElement(null);
            loadMediaFileSystem();
        }
    }, [MediaParent]);

    const value = {
        mediaManagerOpen,
        setMediaManagerOpen,
        websiteId,
        setWebsiteId,
        cancelMediaManager,
        MediaFolders,
        MediaFiles,
        MediaParent,
        setMediaParent,
        MediaFoldersLoading,
        mediaFolderCreateOpen,
        setMediaFolderCreateOpen,
        loadMediaFileSystem,
        UploadingFiles,
        uploadFiles,
        MediaFilesLoading,
        MediaFoldersTrace,
        selectedElement,
        setSelectedElement,
        selectedCallback,
        setSelectedCallback,
        openMediaManagerSelectFile,
    }

    return (
        <MediaManagerContext.Provider
            value={value}
        >
            <MediaManager />
            { mediaFolderCreateOpen && <MediaFolderCreate /> }
            {children}
        </MediaManagerContext.Provider>
    );
}

export const useMediaManager = () => useContext(MediaManagerContext);