import './media_manager.css';
import { MediaFolderItem } from '../MediaFolderItem/MediaFolderItem';
import { MediaFileItem } from '../MediaFileItem/MediaFileItem';
import { useRef } from 'react';
import { useMediaManager } from '../../context/MediaManagerContext';
import { BarLoader } from '../BarLoader/BarLoader';
import { MediaFilesUploading } from '../MediaFilesUploading/MediaFilesUploading';

export const MediaManager = () => {
    //HOOKS
    const {
        cancelMediaManager,
        MediaFolders,
        MediaFoldersLoading,
        MediaFiles,
        setMediaFolderCreateOpen,
        uploadFiles,
        MediaFilesLoading,
        setMediaParent,
        MediaFoldersTrace,
        selectedElement,
        selectedCallback,
        mediaManagerOpen,
    } = useMediaManager();

    const fileRef = useRef();

    const handleFileChange = (e) => {
        uploadFiles(e.target.files);
        fileRef.current.value = "";
    }

    const handleSelect = () => {
        if (selectedCallback) {
            const image = MediaFiles.find(item => item.id === selectedElement.id) || null;
            selectedCallback(image);
        }
    }

    return (
        <>
            <input type='file' multiple ref={fileRef} onChange={handleFileChange} style={{display: 'none'}} id='media_manager_file_input' />
            <div className='media_manager_modal_container' style={mediaManagerOpen ? {display: 'flex'} : {display: 'none'}}>
                <div className='media_manager'>
                    <div className='header'>
                        <div>
                            <h2>
                                Media manager
                            </h2>
                        </div>
                    </div>
                    <div className='body'>
                        <div className='media_folder_trace'>
                            <button onClick={() => setMediaParent(null)}>
                                Storage
                            </button>
                            {
                                MediaFoldersTrace && MediaFoldersTrace.reverse().map((folder) => {
                                    return (
                                        <button onClick={() => setMediaParent(folder.id)}>
                                            {folder.name}
                                        </button>
                                    )
                                })
                            }
                        </div>
                        <div className='folders'>
                            <div className='title'>
                                Folders
                                <button className='primary_btn' onClick={() => setMediaFolderCreateOpen(true)}>
                                    Create folder
                                </button>
                            </div>
                            { MediaFoldersLoading && <BarLoader /> }
                            <div className='container'>
                                {
                                    MediaFolders.map((folder) => {
                                        return <MediaFolderItem key={'media_manager_folder_' + folder.id} folder={folder} />
                                    })
                                }
                            </div>
                        </div>
                        <div className='files'>
                            <div className='title'>
                                Files
                                <label className='primary_btn' htmlFor='media_manager_file_input'>
                                    Upload file
                                </label>
                            </div>
                            { MediaFilesLoading && <BarLoader /> }
                            <div className='container'>
                                {
                                    MediaFiles.map((file) => {
                                        return <MediaFileItem key={'media_manager_file_' + file.id} file={file} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <MediaFilesUploading />

                    <div className='footer'>
                        <div>

                        </div>
                        <div className='actions'>
                            <button className='secundary_btn' onClick={cancelMediaManager}>
                                Cancel
                            </button>
                            <button className='primary_btn' disabled={(selectedElement === null || selectedElement.type !== "file")} onClick={handleSelect}>
                                Select file
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}