import React from "react";
import { Routes } from "react-router-dom";
import { ProtectRoute } from "./ProtectRoute";

export function ProtectedRoutes(props) {
    return (
        <>
            <Routes>
                {props.children}
            </Routes>

            {
                React.Children.map(props.children, child => {
                    const { path } = child.props;

                    return <ProtectRoute path={path} key={"protected_route_" + path} />
                })
            }
        </>
    );
}