import './websites_list.css';
import { WebsiteItem } from '../WebsiteItem/WebsiteItem';
import { forwardRef, useEffect, useState } from 'react';
import { useServer } from '../../context/serverContext';
import { endpoints } from '../../env/ServerEnv';

export const WebsitesList = forwardRef((props, ref) => {
    //HOOKS
    const {getRequest} = useServer();

    //STATES
    const [websites, setWebsites] = useState([]);

    useEffect(() => {
        getRequest(endpoints.websites_list_owned, {}, {}, (res) => {
            if (res[0]) {
                setWebsites(res[1].websites);
            }
        });
    }, []);

    return (
        <>
            <section className='websites_list_section'>
                {
                    websites.map(website => {
                        return (
                            <WebsiteItem 
                                website={website}
                                key={'website_' + website.id}
                            />
                        );
                    })
                }
            </section>
        </>
    );
});