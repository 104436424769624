import './page_loader.css';
import { LogoSimpleNegative } from '../../icons/LogoSimpleNegative';
import { forwardRef, useImperativeHandle, useRef } from "react";
import { BarLoader } from '../BarLoader/BarLoader';

export const PageLoader = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            open: open,
            close, close,
        }
    });

    //REFS
    const loaderRef = useRef();

    //FUNCTIONS
    const open = () => {
        loaderRef.current.classList.add('active');
    }

    const close = () => {
        loaderRef.current.classList.remove('active');
    }

    return (
        <div className='page_loader' ref={loaderRef}>
            <div className='logo'>
                <LogoSimpleNegative />
            </div>
            <div className='loader'>
                <BarLoader />
            </div>
        </div>
    );
});