import './dashboard.css';
import { DashboardNavbar } from '../../components/DashboardNavbar/DashboardNavbar';
import { WebsitesList } from '../../components/WebsitesList/WebsitesList';

export function Dashboard() {
    return (
        <div className='dashboard_app'>
            <DashboardNavbar />
            <section className='new_website_section'>
                <div className='title'>
                    <h2>My sites</h2>
                    <p>Select a site to edit, view and open its dashboard.</p>
                </div>
                <div className='actions'>
                    {/* <button className='primary_btn'>
                        Create website
                    </button> */}
                </div>
            </section>
            <WebsitesList />
        </div>
    );
}