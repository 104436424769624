import { createContext, useContext } from "react";
import { useServer } from "./serverContext";
import { useState } from "react";
import { useEffect } from "react";
import { endpoints } from "../env/ServerEnv";
import { useParams } from "react-router-dom";

const EcommerceOrdersContext = createContext(undefined);

export const EcommerceOrdersProvider = ({children}) => {
    //HOOKS
    const { getRequest } = useServer();
    const {id} = useParams();

    const EcommercePaymentMethods = {
        ORDER_CASH : 0,
        ORDER_WOMPI : 1,
        ORDER_SISTECREDITO : 2,
    }

    const EcommercePaymentMethodsName = {
        0 : "Efectivo",
        1 : "Wompi",
        2 : "Sistecredito",
    }

    //ENUMS
    const EcommerceOrderState = {
        PROCESSING : 0,
        PAYMENT_PENDING : 1,
        PAYMENT_VERIFYING : 2,
        PAYMENT_FAIL : 3,
        PAYMENT_SUCCESSFULL : 4,
        ORDER_PROCESSING : 5,
        ORDER_DELIVERYNG : 6,
        ORDER_COMPLETED : 7,
    }

    const EcommerceOrderStateName = {
        0: "Procesando",
        1: "Pendiente de pago",
        2: "Verificando pago",
        3: "Pago rechazado",
        4: "Pago exitoso",
        5: "Preparando pedido",
        6: "Enviando pedido",
        7: "Compra finalizada",
    }

    const EcommerceOrderStateClass = {
        0: "processing",
        1: "processing",
        2: "processing",
        3: "error",
        4: "",
        5: "",
        6: "",
        7: "",
    }

    //STATES
    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(false);
    const [orderOpen, setOrderOpen] = useState(false);
    const [currentOrder, setCurrentOrder] = useState(null);

    //FUNCTIONS
    const loadOrders = () => {
        setOrdersLoading(true);

        getRequest(endpoints.ecommerce_orders_get, {atze_website_id: id}, {}, (res) => {
            setOrdersLoading(false);

            if (res[0]) {
                res = res[1];
                setOrders(res.orders);
            }
        });
    }

    const getColombiaTime = (time) => {
        const receivedDate = new Date(time);

        const options = {
            timeZone: "America/Bogota",
            hour12: true, // Use 24-hour time format
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        };

        return receivedDate.toLocaleString("es-CO", options);
    }

    const openOrder = (order) => {
        setCurrentOrder(order);
        setOrderOpen(true);
    }

    const closeOrder = () => {
        setCurrentOrder(null);
        setOrderOpen(false);
    }

    const getLocalePrice = (price) => {
        return (price / 100).toLocaleString("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }
    
    //EFFECTS
    useEffect(() => {
        loadOrders();
    }, []);

    const values = {
        EcommerceOrderState,
        orders,
        loadOrders,
        ordersLoading,
        getColombiaTime,
        EcommercePaymentMethods,
        EcommercePaymentMethodsName,
        EcommerceOrderStateName,
        EcommerceOrderStateClass,
        orderOpen,
        setOrderOpen,
        currentOrder,
        setCurrentOrder,
        openOrder,
        getLocalePrice,
        closeOrder,
    }

    return (
        <EcommerceOrdersContext.Provider
            value={values}
        >
            {children}
        </EcommerceOrdersContext.Provider>
    );
}

export const useEcommerceOrders = () => useContext(EcommerceOrdersContext);