import './website_item.css';
import { Link } from 'react-router-dom';
import { forwardRef } from 'react';

export const WebsiteItem = forwardRef((props, ref) => {
    return (
        <div className='website_item'>
            <div className='header'>
                <div className='data'>
                    <div className='title'>
                        <h2>{props.website.name}</h2>
                        <p>Hosting plan: <span>Premium hosting</span></p>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <div>

                </div>
                <div>
                    <Link to={'/dashboard/website/' + props.website.id} className='secundary_btn'>
                        Dashboard
                    </Link>
                </div>
            </div>
        </div>
    );
});