import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useServer } from "./serverContext";
import { endpoints } from "../env/ServerEnv";
import { useNavigate, useParams } from "react-router-dom";
import { CodeEditorDeleteFileModal } from '../components/CodeEditorDeleteFileModal/CodeEditorDeleteFileModal';
import { CodeEditorSavingModal } from "../components/CodeEditorSavingModal/CodeEditorSavingModal";
import { CodeEditorCreateFolderModal } from "../components/CodeEditorCreateFolderModal/CodeEditorCreateFolderModal";
import { CodeEditorCreateFileModal } from "../components/CodeEditorCreateFileModal/CodeEditorCreateFileModal";

const webpageEditorContext = createContext(undefined);

export const WebpageEditorProvider = ({children}) => {
    //HOOKS
    const {getRequest, postRequest} = useServer();
    const {id} = useParams();
    const navigate = useNavigate();

    //REFS
    const deleteModalRef = useRef();
    const savingModalRef = useRef();
    const createFolderRef = useRef();
    const createFileRef = useRef();

    const [fileRoot, setFileRoot] = useState(null);
    const [files, setFiles] = useState({});
    const [fileActive, setFileActive] = useState(0);
    const [openFiles, setOpenFiles] = useState([]);
    const [editorModels, setEditorModels] = useState({});

    const getFiles = (root) => {
        let files = {};

        for (let i = 0; i < root.length; i++) {
            const element = root[i];
            if (element.childs) {
                files = {...files, ...getFiles(element.childs)}
            } else {
                files["file_" + element.id] = element;
            }
        }

        return files;
    }

    const getContent = (fileId) => {
        const model = editorModels["/file_" + fileId];
        if (model) {
            return model.getValue();
        }
        return null;
    }

    const setContent = (fileId, content) => {
        const model = editorModels["/file_" + fileId];
        if (model) {
            model.setValue(content);
        }
    }

    const saveCurrentFile = () => {
        let file = files["file_" + fileActive];
        if (file) {
            savingModalRef.current.open();
            let data = {
                file_id: file.id,
                content: getContent(file.id),
            }

            postRequest(endpoints.website_filesystem_file_save, data, {}, (res) => {
                savingModalRef.current.close();
                let updatedFile = file;
                updatedFile.content = getContent(file.id);

                setFiles((prevFiles) => ({
                    ...prevFiles,
                    ["file_" + file.id]: updatedFile,
                }));
            });
        }
    }

    const saveAllOpenedFiles = () => {
        if (openFiles.length > 0 ) {
            savingModalRef.current.open();
            let payload = [];

            for (let i = 0; i < openFiles.length; i++) {
                const fileId = openFiles[i];
                payload.push({
                    id: fileId,
                    content: getContent(fileId),
                });
            }

            postRequest(endpoints.website_filesystem_file_bulk_save, { files: payload }, {}, (res) => {
                savingModalRef.current.close();

                let newFiles = {};

                for (let i = 0; i < openFiles.length; i++) {
                    const fileId = openFiles[i];
                    let updatedFile = files["file_" + fileId];
                    updatedFile.content = getContent(fileId);

                    newFiles["file_" + fileId] = updatedFile;
                }

                setFiles((prevFiles) => ({
                    ...prevFiles,
                    ...newFiles,
                }));
            });
        }
    }

    const confirmClose = (fileId) => {
        definitiveCloseFile(fileId);
        deleteModalRef.current.close();
    }

    const saveClose = (fileId) => {
        deleteModalRef.current.setDisable(true);
        deleteModalRef.current.loaderRef.current.classList.add('active');

        let data = {
            file_id: fileId,
            content: getContent(fileId),
        }

        postRequest(endpoints.website_filesystem_file_save, data, {}, (res) => {
            deleteModalRef.current.setDisable(false);
            deleteModalRef.current.loaderRef.current.classList.remove('active');
            deleteModalRef.current.close(true);

            let updatedFile = files["file_" + fileId];
            updatedFile.content = getContent(fileId);

            setFiles((prevFiles) => ({
                ...prevFiles,
                ["file_" + fileId]: updatedFile,
            }));

            setTimeout(() => {
                definitiveCloseFile(fileId);
            }, 100);
        });
    }

    const definitiveCloseFile = (fileIdToRemove) => {
        if (fileIdToRemove === fileActive) {
            if (openFiles.length > 1) {
                const pos = openFiles.indexOf(fileIdToRemove);
                const newPos = pos === (openFiles.length - 1) ? 0 : pos + 1;
                setFileActive(openFiles[newPos]);
            } else {
                setFileActive(0);
            }
        }

        setOpenFiles((prevOpenFiles) =>
            prevOpenFiles.filter((fileId) => fileId !== fileIdToRemove)
        );

        setContent(fileIdToRemove, files["file_" + fileIdToRemove].content);
    }

    const closeFile = (fileId) => {
        let newContent = getContent(fileId);
        if (files["file_" + fileId].content !== newContent) {
            deleteModalRef.current.open(files["file_" + fileId]);
        } else {
            //SAME CONTENT JUST CLOSE
            definitiveCloseFile(fileId);
        }
    }

    const openCreateFolder = (parent = 0) => {
        createFolderRef.current.open(parent);
    }

    const openCreateFile = (parent = 0) => {
        createFileRef.current.open(parent);
    }

    const getFileSystem = () => {
        getRequest(endpoints.website_filesystem_get, { website_id: id}, {}, (res) => {
            if (res[0]) {
                res = res[1];

                if (res.success) {
                    setFileRoot(res.filesystem);
                } else {
                    navigate('/dashboard', {replace:true});
                }
            }
        });
    }

    useEffect(() => {
        getFileSystem();
    }, []);

    useEffect(() => {
        if (fileRoot !== null) {
            setFiles(getFiles(fileRoot.childs));
        }
    }, [fileRoot]);

    useEffect(() => {
        if (fileActive !== 0) {
            if (!openFiles.includes(fileActive)) {
                setOpenFiles(PrevFiles => [...PrevFiles, fileActive]);
            }
        }
    }, [fileActive]);

    return (
        <webpageEditorContext.Provider
            value={{
                fileRoot: fileRoot,
                fileActive: fileActive,
                setFileActive: setFileActive,
                files: files,
                openFiles: openFiles,
                closeFile: closeFile,
                editorModels: editorModels,
                setEditorModels: setEditorModels,
                saveCurrentFile: saveCurrentFile,
                saveAllOpenedFiles: saveAllOpenedFiles,
                openCreateFolder: openCreateFolder,
                openCreateFile: openCreateFile,
            }}
        >
            <CodeEditorSavingModal ref={savingModalRef} />
            <CodeEditorDeleteFileModal saveCallback={saveClose} dontSaveCallback={confirmClose} ref={deleteModalRef} />
            <CodeEditorCreateFolderModal ref={createFolderRef} getFileSystem={getFileSystem} />
            <CodeEditorCreateFileModal ref={createFileRef} getFileSystem={getFileSystem} />

            {children}
        </webpageEditorContext.Provider>
    );
}

export const useWebpageEditor = () => useContext(webpageEditorContext);