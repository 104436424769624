import './dashboard_navbar.css';
import { forwardRef, useImperativeHandle } from 'react';
import { LogoSimpleNegative } from '../../icons/LogoSimpleNegative';
import { MySitesDropdown } from '../MySitesDropdown/MySitesDropdown';
import { AccountDropdown } from '../AccountDropdown/AccountDropdown';
import { useNavigate } from 'react-router-dom';

export const DashboardNavbar = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {

        };
    });

    const navigate = useNavigate();

    return (
        <div className='dashboard_navbar'>
            <div className='col1'>
                <div className='logo' onClick={() => navigate('/dashboard', {replace: true})}>
                    <LogoSimpleNegative />
                </div>
                <MySitesDropdown />
            </div>
            <div className='col2'>
                <div className='divider'>
        
                </div>
                <AccountDropdown />
            </div>
        </div>
    );
});